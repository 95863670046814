.notification-modal {
  background-color: transparent !important;
  box-shadow: none;
  margin: 0;
  top: 130px;
  left: 30px;
  .ant-modal-header {
    padding: 8px 16px;
    background-color: #f8f8f8;
    .ant-modal-title {
      text-transform: uppercase;
      font-family: "Open Sans", Arial;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0px;
      color: rgba(56, 56, 56, 1);
      font-style: normal;
      text-align: left;
    }
  }
  .ant-modal-body {
    padding: 0;
    max-height: 50vh;
    min-height: 40vh;
    border-radius: 3px;
    overflow-y: scroll; // overlay
    &::before {
      content: "";
      display: block;
      width: 90%;
      margin: 0em auto 0;
      border-bottom: 2px solid rgba(252, 185, 184, 1);
    }
  }
  .ant-modal-body::-webkit-scrollbar {
    display: none;
  }
  .ant-modal-content,
  .ant-modal-header {
    border-radius: 3px 3px 0px 0px !important;
    // border-bottom: 1px solid #eb078e;
    border-bottom: none;
  }

  .ant-modal-content {
    border: 1px solid rgba(226, 28, 33, 1);
    box-shadow: 1px 1px 3px rgb(226 28 33 / 50%);
    margin-right: 3px;
    margin-left: 2px;
    background-color: rgba(248, 248, 248, 1);
    border-radius: 3px;
    // overflow-y: auto;
  }

  .ant-list-header {
    margin: 10px 7px;
    padding: 0px 8px !important;
    background-color: rgba(252, 185, 184, 1);
    border: none;
    border-radius: 3px;
    height: 20px;
    line-height: 20px;
    span {
      line-height: 13px;
      letter-spacing: 0px;
      color: rgba(56, 56, 56, 1);
      font-family: "Open Sans", Arial;
      font-weight: 700;
      font-style: normal;
      font-size: 11px;
      text-align: left;
    }
  }
  .ant-list-bordered {
    border: none;
  }
  .ant-modal-close-x {
    font-size: 14px;
  }
  .ant-modal-close {
    right: -5px;
    top: -13px;
  }
  .anticon-close {
    color: grey !important;
  }

  .notifications-list {
    .ant-list-item {
      display: block;
    }
    .time-div {
      font-size: 11px;
      font-weight: 400;
      line-height: 13px;
      letter-spacing: 0px;
      color: rgba(113, 113, 113, 1);
      font-family: "Open Sans", Arial;
      font-style: normal;
    }
    .message-body {
      color: #383838;
      font-size: 11px;
      font-weight: 400;
      line-height: 13px;
      letter-spacing: 0px;
      color: rgba(56, 56, 56, 1);
      font-family: "Open Sans", Arial;
      font-style: normal;
      padding: 4px 0px;
    }
    .unread-listitem {
      cursor: pointer;
    }
    .unread-message {
      font-weight: 700;
    }
  }
}

@media screen and (max-width: 575px) {
  .notification-modal {
    right: 0;
    margin-left: auto;
  }
}

@primary-color: #e21c21;