.production-manager-parent-row {
  .heading-col {
    padding: 2px 25px 8px 12px;
    color: #e21c21;
    .export-icon {
      cursor: pointer;
      height: 24px;
      width: 24px;
    }
  }
  .add-template-btn {
    border: 1px solid #e21c21;
    width: 100px;
    color: #e21c21;
    svg {
      fill: #e21c21;
    }
  }
}
.production-template-parent-row .heading-col .heading-template {
  line-height: 17px;
  letter-spacing: 0px;
  color: rgb(0, 0, 0);
  font-family: 'Open Sans', Arial;
  font-weight: 700;
  text-transform: uppercase;
}

.intervention-types {
  .target-year {
    @media screen and (min-width: 1600px) {
      padding-left: 2% !important;
    }
  }
  .coach-target-per-year {
    @media screen and (min-width: 1600px) {
      padding-left: 1% !important;
    }
    div {
      @media screen and (min-width: 1600px) {
        padding-left: 5% !important;
      }
    }
  }
  .per-input {
    width: 71px;
    @media screen and (max-width: 1399px) {
      width: 60px !important;
    }
    @media screen and (max-width: 1299px) {
      width: 50px !important;
    }
    @media screen and (max-width: 1199px) {
      width: 40px !important;
    }
  }
  .percentage-input {
    // @media screen and (max-width: 1299px) {
    //   width: 50px !important;
    // }
    @media screen and (max-width: 1199px) {
      width: 45px !important;
      padding: 0px !important;
    }
  }
  .target-type-selector {
    @media screen and (max-width: 1200px) {
      width: 140px;
    }
  }
  .total-per-div {
    div {
      background-color: #f0f0f0;
      border: 1px solid #555;
      border-color: #555 !important;
      border-radius: 2px;
      box-shadow: none;
      color: #555;
      text-align: center;
      width: 71px;
      font-size: 13px;
      padding: 3px 11px;
      line-height: 1.5715;
      height: 22px;
      vertical-align: middle;
      margin: 0 auto;
    }
  }
}

.document-type-section {
  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    padding: 0px;
  }
  .campus-checkbox-col {
    margin-bottom: 20px;
    .ant-checkbox + span {
      text-transform: none;
    }
    .ant-checkbox {
    }
    .ant-checkbox-checked::after {
    }
    // .ant-checkbox-checked .ant-checkbox-inner::after {
    //   border: 2px solid #383838;
    // }
    // .ant-checkbox-checked .ant-checkbox-inner {
    //   background-color: #ffffff;
    //   border-color: #383838;
    // }
    label {
      padding-left: 0px !important;
    }
    line-height: 16px;
    letter-spacing: 0px;
    color: rgba(56, 56, 56, 1);
    font-family: 'Open Sans', Arial;
    font-weight: 600;
    font-style: normal;
    font-size: 13px;
    text-align: left;
    text-transform: none;
  }
  .label {
    color: #383838;
    font-family: 'Open Sans', Arial;
    font-weight: 700;
    font-style: normal;
    font-size: 11px;
    padding-left: 10px;
    line-height: 13px;
    text-transform: uppercase;
    letter-spacing: 0px;
  }
  .weighting-target-year .label {
    @media screen and (max-width: 991px) {
      padding-left: 2px !important;
    }
  }
  .input {
    text-align: center;
    width: 71px;
    // border: 1px solid #555555;
    // color: #555555;
    border-radius: 2px;
    // background-color: #f0f0f0;
    // border-color: #555555 !important;
    // box-shadow: none;
    padding: 0px 8px;
    // margin: 0px auto;
    // margin: 5px 18px 0px 18px;
  }
  .label-input-col {
    // display: flex;
    // justify-content: center;
    // text-align: center;
  }
  .sum-of-all-labels {
    .target-year {
      @media screen and (max-width: 1599px) {
        padding-left: 4%;
      }
      // @media screen and (max-width: 1299px) {
      //   padding-left: 2%;
      // }
      // @media screen and (max-width: 1199px) {
      //   padding-left: 4%;
      // }
      // @media screen and (max-width: 991px) {
      //   padding-left: 15px;
      // }
    }
    .weighting {
      padding-left: 3%;
      @media screen and (max-width: 1599px) {
        padding-left: 3%;
      }
      @media screen and (max-width: 1199px) {
        padding-left: 1%;
      }
    }
    .pre {
      padding-left: 3%;
      @media screen and (max-width: 1599px) {
        padding-left: 5%;
      }
      @media screen and (max-width: 1199px) {
        padding-left: 1%;
      }
      @media screen and (max-width: 991px) {
        padding-left: 3%;
      }
    }
    .post {
      padding-left: 1%;

      @media screen and (max-width: 1599px) {
        padding-left: 5%;
      }
      @media screen and (max-width: 1199px) {
        padding-left: 3%;
      }
      @media screen and (max-width: 991px) {
        padding-left: 4%;
      }
    }
    .total-weighting {
      padding-left: 2%;
    }
  }
  .total-col {
    margin-right: 6%;
    @media screen and (max-width: 1599px) {
      margin-right: 4%;
    }
    @media screen and (max-width: 1199px) {
      margin-right: 7%;
    }
    @media screen and (max-width: 991px) {
      margin-right: 1%;
    }
    text-align: center;
    div {
      line-height: 1;
    }
    label {
      line-height: 13px;
      &::after {
        content: '';
      }
    }
    input,
    .ant-input:hover,
    .ant-input:active,
    .ant-input:hover {
      text-align: center;
      width: 71px;
      border: 1px solid rgba(85, 85, 85, 1);
      color: rgba(85, 85, 85, 1);
      border-radius: 2px;
      background-color: rgba(240, 240, 240, 1);
      border-color: rgba(85, 85, 85, 1) !important;
      box-shadow: none;
    }
    span {
      line-height: 12px;
      letter-spacing: 0px;
      color: rgba(56, 56, 56, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 500;
      font-style: italic;
      font-size: 10px;
      text-align: center;
    }
  }
  .doc-type-number-form-item {
    margin-bottom: 10px;
    .ant-form-item-row {
      gap: 3px;
    }
  }
  .ant-col-24.ant-form-item-label {
    line-height: 1;
    white-space: nowrap;
    label {
      height: 16px !important;
      margin-bottom: 1px;
    }
  }
  .ant-form-item > .ant-form-item-row {
    flex-flow: column !important;
    .ant-form-item-label {
      text-align: start !important;
    }
  }
  .ant-form-item-has-error .ant-picker:not(.ant-picker-disabled) {
    border-radius: 0px;
    box-shadow: none;
    border: 1px solid #ff4d4f;
  }

  .ant-picker-input input {
    padding-left: 2px !important;
  }

  .ant-picker.ant-picker-disabled {
    background: #fff;
    border-color: transparent;
    cursor: text;
    .ant-picker-input::after {
      display: none;
    }
  }
  .flex-column {
    flex-flow: column !important;
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #383838;
  }
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
  }
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: #383838;
      &::after {
        background-color: #383838;
      }
    }

    &::after {
      border: 1px solid #383838;
    }
  }

  .ant-checkbox-checked {
    &::after {
      border: 1px solid #383838;
    }
    .ant-checkbox-inner {
      background-color: #383838;
      border-color: #383838;
    }
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #383838;
  }
  .template-type {
    display: inline-block;
    .projects {
      margin-left: 25px;
    }
  }
  .save-btn {
    background-color: #e21c21;
    border-color: #e21c21;
    text-transform: uppercase;
  }
  .field-button {
    width: 10%;
    color: #e21c21;
    border-color: #e21c21;
  }
  .project-radio {
    text-transform: capitalize !important;
  }

  .mass-name {
    padding-top: 10px;
    line-height: 16px;
    letter-spacing: 0px;
    color: rgba(56, 56, 56, 1);
    font-family: 'Open Sans', Arial;
    font-weight: 500;
    font-style: normal;
    font-size: 13px;
  }
}

.text-document-template {
  line-height: 2.5715;
  padding-left: 10px;
  color: rgba(56, 56, 56, 1);
  font-family: 'Open Sans', Arial;
  font-weight: 400;
  font-size: 10px;
}
.text-complete-template {
  line-height: 16px;
  letter-spacing: 0px;
  color: rgb(56, 56, 56);
  font-family: 'Open Sans', Arial;
  font-weight: 600 !important;
  text-align: left;
  font-size: 10px !important;
  margin-left: 10px;
  font-style: normal;
  width: auto;
}
.div-text {
  padding-bottom: 10px;
  .checkbox-div {
    label {
      text-transform: capitalize;
      color: rgba(56, 56, 56, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 600;
      font-size: 13px;
    }
  }
}

.doc-type-table {
  .ant-table-column-title {
    .ant-input,
    .ant-select-selector {
      border-color: #c6c6c6;
    }
    .ant-select-selector,
    .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      height: 25px;
    }
  }
}
.li-div {
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 22px;
    transition: all 0.3s;
    font-size: 13px;
  }
}

.doc-template-parent-div {
  label {
    color: rgb(56, 56, 56);
    font-family: 'Open Sans', Arial;
    font-weight: 700;
    font-size: 11px;
    text-align: left;
  }
  .percentage-input {
    width: 71px;
    padding: 0px 8px;
    text-align: center;
  }
  .manual-row {
    .center {
      text-align: center;
    }
  }
  .choose-col {
    .choose-select-div {
      margin: 15px 0px 30px 0px;
    }
  }
  .add-field-btn {
    width: 106px;
    height: 34px;
    color: #e21c21;
    border: 1px solid #e21c21;
    margin: 10px 0px 30px 0px;
  }

  .border-less-input {
    color: rgb(56, 56, 56);
    font-family: 'Open Sans', Arial;
    font-weight: 400;
    font-size: 13px;
    text-align: left;
    box-shadow: none;
  }
  .field-row {
    margin: 15px 0px 30px 0px;
  }
  .select-options-parent-row {
    .add-option-btn {
      width: 94px;
      color: rgba(226, 28, 33, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 600;
      font-style: normal;
      font-size: 12px;
      height: 26px;
      margin-left: 40px;
      margin-bottom: 20px;
    }
  }
}

@primary-color: #e21c21;