.project-details-section {
  .ant-col-24.ant-form-item-label {
    line-height: 1;
    white-space: nowrap;
  }
  .ant-form-item-has-error .ant-picker:not(.ant-picker-disabled) {
    border-radius: 0px;
    box-shadow: none;
    border: 1px solid #ff4d4f;
  }

  .ant-picker-input input {
    padding-left: 2px !important;
  }

  .ant-picker.ant-picker-disabled {
    background: #fff;
    border-color: transparent;
    cursor: text;
    .ant-picker-input::after {
      display: none;
    }
  }
}

.project-attach-parent-div {
  margin-left: 10px;
  margin-bottom: 10px;
  .text-span {
    color: #383838;
    font-family: "Open Sans", Arial;
    font-weight: 700;
    font-style: normal;
    font-size: 11px;
    // padding-left: 5px;
  }
  label {
    padding-left: 0px !important;
    .ant-btn {
      margin-top: 5px;
      width: 113px;
      height: 26px;
      padding: 0px 2px;
      span {
        margin-left: 4px;
      }
    }
    .ant-btn-loading {
      width: 137px;
    }
  }
  .image-name {
    cursor: pointer;
  }
}

.attach-listing-div {
  .name-col {
    cursor: pointer;
    .name {
      text-decoration: underline;
      color: rgb(56, 56, 56);
      font-family: "Open Sans", Arial;
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
    }
  }
  .del-icon {
    fill: #e21c21;
    width: 15px;
    height: 15px;
  }
}

.doc-control-section {
  margin-bottom: 40px;
  .label-text {
    line-height: 16px;
    letter-spacing: 0px;
    color: rgba(56, 56, 56, 1);
    font-family: "Open Sans", Arial;
    font-weight: 700;
    font-style: normal;
    font-size: 13px;
    margin-bottom: 10px;
  }
}

.doc-control-section {
  .ant-col-24.ant-form-item-label {
    line-height: 1;
  }
}
.add-attach-btn {
  margin-left: 1px !important;
  margin-right: 185px !important;
  row-gap: 10px;
}
.scope {
  margin-top: 5px;
}
.name-text{
  font-size: 13px;
}

@primary-color: #e21c21;