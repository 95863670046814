.heading-parent-div {
  .empty-div {
    margin-top: 60px;
  }
  svg {
    cursor: pointer;
  }
  .ant-input {
    padding: 0px 11px;
  }
  .is-edit {
    .ant-input,
    .ant-input-number-disabled {
      border: none;
    }
    .ant-input-disabled,
    .ant-input-number-disabled {
      cursor: text;
      background-color: #fff;
    }
    .ant-select-selector {
      border: none;
    }
    .ant-select-disabled .ant-select-arrow {
      cursor: text;
      display: none;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector,
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector
      input,
    .ant-input-number-disabled .ant-input-number-input,
    .ant-picker.ant-picker-disabled,
    .ant-picker-input > input[disabled] {
      background: #fff;
      cursor: text;
    }
    .ant-picker.ant-picker-disabled {
      border: none;
      .ant-picker-input::after {
        background-image: none;
        width: 0px;
      }
    }

    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after,
    .ant-checkbox-disabled .ant-checkbox-inner {
      border-color: rgba(56, 56, 56, 1) !important;
      background-color: #fff;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector
      input,
    .ant-checkbox-disabled .ant-checkbox-input,
    .ant-checkbox-disabled,
    .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
      cursor: text;
    }
    .ant-input[disabled],
    .ant-input-number-disabled,
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector,
    .ant-checkbox-disabled + span {
      color: rgba(56, 56, 56, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 400;
      font-size: 13px;
      cursor: text;
    }
  }
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder,
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 24px;
    z-index: 1;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-number-input {
    height: 24px;
  }
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 22px;
  }
  .back-btn,
  .add-department-btn,
  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(226, 28, 33, 1) !important;
    background: #fff;
    // height: 40px;
    color: rgba(226, 28, 33, 1) !important;
    svg {
      fill: #e21c21 !important;
    }
    .anticon + span {
      margin-left: 2px;
      color: #e21c21 !important;
    }
  }
  .back-btn {
    margin-right: 40px;
    width: 100px;
  }
  .add-department-btn {
    width: 114px;
  }
  .user-manager-parent-div {
    margin-top: 20px;
    box-shadow: 1px 1px 4px 1px rgba(56, 56, 56, 0.4) !important;
    background-color: rgb(255, 255, 255);
    padding: 10px 15px;
    border-radius: 8px;
    margin-bottom: 30px;
    width: 100%;
    .heading-col {
      border-bottom: 1px solid #e21c21 !important;
      padding: 10px 25px 10px 12px;
      margin-bottom: 25px;
      .heading-span {
        line-height: 17px;
        letter-spacing: 0px;
        color: #e21c21 !important;
        font-family: 'Open Sans', Arial;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    .simple-heading-col {
      width: 96%;
      margin-left: auto;
      border-bottom: 1px solid rgba(113, 113, 113, 1);
      padding: 10px 25px 10px 12px;
      margin-bottom: 20px;
      .heading-span {
        text-transform: uppercase;
        line-height: 16px;
        letter-spacing: 0px;
        color: rgb(56, 56, 56);
        font-family: 'Open Sans', Arial;
        font-weight: 700;
        font-size: 13px;
      }
      @media screen and (max-width: 800px) {
        width: 100%;
      }
    }
    label {
      color: rgba(56, 56, 56, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 700;
      font-style: normal;
      font-size: 11px;
      padding-left: 10px;
      text-transform: uppercase;
    }
    .checkbox-label {
      margin-bottom: 10px;
      display: block;
    }
    .permission-checkbox {
      margin-bottom: 20px;
      label {
        font-weight: 500;
        text-transform: unset;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: white;
        border-color: #d1d1d1;
      }
      .ant-checkbox-checked::after,
      .ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #d1d1d1;
      }
      .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: grey;
      }
    }
    .settings-section {
      margin-bottom: 100px;
    }
    .permissions-section {
      margin-bottom: 40px;
    }
    .section-body {
      .heading-text {
        text-transform: uppercase;
        color: rgb(56, 56, 56);
        font-family: 'Open Sans', Arial;
        font-weight: 700;
        font-style: normal;
        font-size: 11px;
        margin-bottom: 6px;
      }
    }
  }
  .user-details-section {
    .password-div {
      color: #204596 !important;
      font-family: 'Open Sans', Arial;
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
      margin-top: 3px;
      cursor: pointer;
      span {
        padding-left: 10px;
      }
    }
    .send-details-parent-div {
      display: flex;
      justify-content: center;
      .send-details-div {
        margin-top: 80px;
        border: 1px solid #e21c21;
        border-radius: 2px;
        width: 127px;
        height: 44px;
        text-align: center;
        color: #e21c21;
        font-family: 'Open Sans', Arial;
        font-weight: 500;
        font-style: normal;
        font-size: 13px;
        cursor: pointer;
        span {
          display: block;
        }
      }
    }
    .user-detail-form {
      .ant-divider {
        border-top: 1px solid #aaaaaa;
      }
      .ant-input-number {
        width: 100%;
      }
      .ant-form-vertical .ant-form-item-label,
      .ant-col-24.ant-form-item-label,
      .ant-col-xl-24.ant-form-item-label {
        padding: 0px;
      }
      .ant-form-item-label > label {
        height: 16px !important;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
      }
      .ant-form-item {
        margin-bottom: 10px;
      }

      .department-select .ant-select-arrow svg {
        fill: #717171;
      }
      .auto-fill-parent-row {
        .label {
          color: rgba(56, 56, 56, 1);
          font-family: 'Open Sans', Arial;
          font-weight: 700;
          font-style: normal;
          font-size: 11px;
          padding-left: 10px;
          text-transform: uppercase;
          line-height: 13px;
          letter-spacing: 0px;
          text-align: left;
        }
        .fill-div {
          line-height: 16px;
          letter-spacing: 0px;
          color: rgba(56, 56, 56, 1);
          font-family: 'Open Sans', Arial;
          font-weight: 400;
          font-style: normal;
          font-size: 13px;
          text-align: left;
          line-height: 13px;
        }
        .fill-col {
          margin-bottom: 10px;
        }
        .block-row {
          margin-bottom: 10px;
        }
      }
      .templates-fields-label label {
        text-decoration: underline;
        line-height: 14px;
        letter-spacing: 0px;
        color: rgb(56, 56, 56);
        font-family: 'Open Sans', Arial;
        font-weight: 700;
        font-style: normal;
        font-size: 12px;
      }
      .templates-fields-parent {
        .ant-form-item-label {
          padding: 0px;
          line-height: 1.2;
          label {
            line-height: 13px;
            letter-spacing: 0px;
            color: rgb(56, 56, 56);
            font-family: 'Open Sans', Arial;
            font-weight: 700;
            font-style: normal;
            font-size: 11px;
            height: 13px;
            text-transform: uppercase;
            padding-left: 10px;
          }
        }
        .ant-form-item-row {
          .ant-form-item-label
            > label.ant-form-item-required:not(
              .ant-form-item-required-mark-optional
            )::before {
            content: none;
          }
        }
        .ant-form-item-control-input {
          min-height: 24px;
          height: 24px;
          .ant-input {
            height: 24px;
            padding-left: 10px !important;
          }
          .ant-picker:not(.ant-picker-range) .ant-picker-input input {
            padding-left: 10px !important;
          }
          .ant-picker {
            padding: 0px 8px 0px 0px;
            height: 24px;
          }
          .ant-picker-input {
            padding-left: 0px !important;
            &::after {
              margin-right: 20px;
            }
          }
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector {
            height: 24px;
            padding: 0 5px !important;
          }
          .ant-select-single .ant-select-selector .ant-select-selection-item,
          .ant-select-single
            .ant-select-selector
            .ant-select-selection-placeholder {
            padding-left: 5px;
            line-height: 24px;
          }
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector
            .ant-select-selection-search-input {
            height: 24px;
          }
        }
      }

      .ant-form-item-has-error .ant-picker:not(.ant-picker-disabled) {
        box-shadow: none;
        border: 1px solid #ff4d4f;
        border-radius: 2px;
      }
      .lgbtq_field {
        .ant-checkbox-checked {
          &::after {
            border: 1px solid #383838;
          }
          .ant-checkbox-inner {
            // background-color: #383838;
            border-color: #383838;
          }
        }
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: white;
        }

        .ant-checkbox-checked .ant-checkbox-inner::after {
          border: 2px solid #383838;
          border-top: 0;
          border-left: 0;
        }

        .ant-checkbox-wrapper:hover .ant-checkbox-inner,
        .ant-checkbox:hover .ant-checkbox-inner,
        .ant-checkbox-input:focus + .ant-checkbox-inner {
          border-color: #383838;
        }

        .ant-checkbox-wrapper {
          align-items: center;
          .ant-checkbox-inner {
            border: 2px solid #3D3D3D !important;
            border-color: #3D3D3D !important;
          }
        }
        .ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item
          input[type='checkbox'] {
          width: 16px;
          height: 16px;
        }
        .ant-checkbox-inner {
          width: 16px;
          height: 16px;
        }
        .ant-checkbox + span {
          padding-top: 6px;
          line-height: 14px;
          letter-spacing: 0px;
          color: rgba(56, 56, 56, 1);
          font-family: 'Open Sans', Arial;
          font-weight: 500;
          font-style: normal;
          font-size: 12px;
        }
      }
    }
    .permissions-div {
      text-transform: capitalize;
    }
    .photo-col {
      text-align: center;
      color: rgba(56, 56, 56, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 700;
      font-style: normal;
      font-size: 11px;
      padding-left: 10px;
      .edit-image {
        border: 1px solid rgb(206, 212, 218);
      }
      .img-parent-div {
        width: 100px;
        height: 100px !important;
        margin: auto;
        border-radius: 3px;
        position: relative;
        margin-top: 5px !important;
        .img-nested-div {
          width: 82px;
          height: 82px;
          margin-top: 6px !important;
          img {
            border-radius: 70px;
            vertical-align: text-top;
            object-fit: cover;
          }
        }
        .edit-icon-div {
          position: absolute;
          bottom: 0px;
          right: 5px;
          color: red !important;
        }
      }
      .text-div {
        max-width: 133px;
        font-size: 10px;
        font-weight: normal;
        letter-spacing: 0px;
        margin: 5px auto;
        line-height: 12px;
        color: #717171;
      }
    }
  }
}

.create-password-modal {
  .ant-modal-content {
    border: 2px solid #2660a4 !important;
    .ant-modal-close-icon svg {
      fill: #2660a4 !important;
    }
  }
  .ant-modal-header {
    border-bottom-color: #2660a4 !important;
    padding: 12px 8px 6px 8px;
    margin: 0 16px;
    .ant-modal-title {
      margin-top: 20px;
      color: #2660a4 !important;
      font-family: 'Open Sans', Arial;
      font-weight: 700;
      font-style: normal;
      font-size: 14px;
      border-bottom: none;
      text-transform: uppercase;
    }
  }
  .ant-modal-body {
    padding: 16px 24px 32px 24px;
    label {
      color: rgb(56, 56, 56);
      font-family: 'Open Sans', Arial;
      font-weight: 700;
      font-style: normal;
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
      padding-left: 12px;
    }
    .text-div {
      color: rgb(56, 56, 56);
      font-family: 'Open Sans', Arial;
      font-weight: 400;
      font-style: normal;
      font-size: 11px;
      text-align: left;
      line-height: 13px;
    }
    .ant-input-affix-wrapper {
      height: 24px;
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      border-color: #2660a4 !important;
    }
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      box-shadow: 0 0 0 2px rgb(41 170 225 / 20%);
    }
    .confirm-btn {
      height: 30px;
      width: 127px;
      margin: 0 auto;
    }
  }
}

.pin-modal {
  .ant-modal-body label {
    padding-left: 0px;
  }
  input {
    width: 27px !important;
    margin-right: 12px !important;
  }
}

.permission-form {
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '';
  }
}

.parent-div {
  width: 100%;
  margin-bottom: 30px;
  .multiple-col {
    width: 12.5%;
    // margin-bottom: 10px;
    @media screen and (max-width: 1450px) {
      width: 16%;
    }
    @media screen and (max-width: 1300px) {
      width: 18%;
    }
    @media screen and (max-width: 1199px) {
      width: 25%;
    }
    @media screen and (max-width: 991px) {
      width: 20%;
    }
    @media screen and (max-width: 800px) {
      width: 25%;
    }
  }
  .settings-div {
    line-height: 13px;
    letter-spacing: 0px;
    color: rgb(56, 56, 56);
    font-family: 'Open Sans', Arial;
    font-weight: 700;
    font-style: normal;
    font-size: 11px;
    padding-left: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    margin-top: 10px;
  }
  .upper-row {
    width: 25%;
    // margin-right: 30px;
  }
  .border {
    border: 1px solid #e2e2e2;
  }
  .npo-col {
    width: 148px !important;
    margin-left: 15px;
  }
  .general-permissions-section {
    .label-div {
      line-height: 16px;
      span {
        letter-spacing: 0px;
        color: rgb(56, 56, 56);
        font-family: 'Open Sans', Arial;
        font-weight: 600;
        font-style: normal;
        font-size: 13px;
        padding-left: 10px;
        line-height: 13px;
      }
      .uppercase-label {
        text-transform: capitalize;
      }
    }
    .roles-checkbox-div {
      margin: 6px 0px !important;
    }
    .checkbox-div {
      margin: 11px 0px;
      label span {
        color: rgba(56, 56, 56, 1);
        font-family: 'Open Sans', Arial;
        font-weight: 400;
        font-size: 13px;
        text-transform: capitalize;
        line-height: 16px;
      }
      // margin-bottom: 20px;
      label {
        font-weight: 500;
        text-transform: unset;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: white;
        border-color: #d1d1d1;
      }
      .ant-checkbox-checked::after,
      .ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #d1d1d1;
      }
      .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: grey;
      }
    }
  }
}

.table-permission-section {
  width: 100%;
  margin-bottom: 30px;
  .block-row {
    border: 1px solid #e2e2e2;
    width: 428px;
    align-items: start;
    padding: 10px;
    justify-content: space-between;
    .heading {
      line-height: 13px;
      letter-spacing: 0px;
      color: rgb(56, 56, 56);
      font-family: 'Open Sans', Arial;
      font-weight: 700;
      font-style: normal;
      font-size: 11px;
      text-align: left;
      margin-bottom: 12px;
    }
  }
  .project-roles-row {
    border: 1px solid #e2e2e2;
    align-items: end;
    width: 257px;
    height: 100%;
    .upper-row {
      align-self: flex-start;
      margin-right: 0px;
    }
  }
  .notifications-switch {
    margin: 15px 10px;
  }
  .multiple-col {
    width: 12%;
    @media screen and (max-width: 1400px) {
      width: 14.25%;
    }
    @media screen and (max-width: 1199px) {
      width: 20%;
    }
  }
  .settings-div {
    line-height: 13px;
    letter-spacing: 0px;
    color: rgb(56, 56, 56);
    font-family: 'Open Sans', Arial;
    font-weight: 700;
    font-style: normal;
    font-size: 11px;
    padding-left: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  .upper-row {
    // width: 118px;
    margin-right: 9px;
    // border: 1px solid #e2e2e2;
  }
  .npo-col {
    width: 148px !important;
  }
  .general-permissions-section {
    .label-div {
      span {
        // text-transform: uppercase;
        color: rgb(56, 56, 56);
        font-family: 'Open Sans', Arial;
        font-weight: 700;
        font-style: normal;
        font-size: 11px;
        // padding-left: 10px;
        line-height: 13px;
      }
      .uppercase-label {
        text-transform: capitalize;
      }
    }
    .roles-checkbox-div {
      margin: 6px 0px !important;
    }
    .checkbox-div {
      margin: 11px 0px;
      label {
        padding-left: 0px;
        span {
          color: rgba(56, 56, 56, 1);
          font-family: 'Open Sans', Arial;
          font-weight: 400;
          font-size: 13px;
          text-transform: capitalize;
        }
      }
      .border-less {
        .ant-checkbox-inner {
          border-color: transparent !important;
          background-color: transparent !important;
          cursor: default;
        }
      }
      .ant-checkbox-wrapper {
        color: rgba(56, 56, 56, 1);
        font-family: 'Open Sans', Arial;
        font-weight: 400;
        font-size: 13px;
      }
      // margin-bottom: 20px;
      label {
        font-weight: 500;
        text-transform: unset;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: white;
        border-color: #d1d1d1;
      }
      .ant-checkbox-checked::after,
      .ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #d1d1d1;
      }
      .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: grey;
      }
    }
  }
}

.project-permission-table
  table
  > tbody
  > tr.expand-parent:hover
  td
  .custom-icon,
.project-permission-table
  table
  > tbody
  > tr.expand-parent:hover
  td
  .rotate-icon {
  fill: #e21c21 !important;
}

.project-permission-table {
  .disable-icon {
    cursor: not-allowed;
  }
  .profile-case-selector {
    .ant-select-selector {
      color: rgba(56, 56, 56, 1);
      font-weight: 400;
      font-size: 13px;
      background: transparent;
      cursor: default;
    }
  }
  .ant-table-tbody > tr > td {
    font-size: 13px;
    color: rgba(56, 56, 56, 1);
  }
  tbody > tr.expand-parent,
  table > tbody > tr.expand-parent:hover,
  tbody > tr.expand-parent:hover td,
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: transparent !important;
    color: rgba(56, 56, 56, 1);
    font-weight: 400;
    font-size: 13px;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td svg,
  .special-table .ant-table-tbody > tr > td.ant-table-cell-row-hover svg,
  tbody > tr.expand-parent:hover td .custom-icon,
  .special-table table > tbody > tr.expand-parent:hover td .rotate-icon {
    fill: #e21c21 !important;
  }

  .expand-parent {
    td {
      border-bottom: 1px solid transparent;
      padding: 16px 12px 0px 12px !important;
    }
    .expand-parent {
      background-color: transparent;
      // color: #fff;
    }
  }
  .status-field {
    padding-left: 10px;
  }
  .permission-table {
    padding-left: 0px !important;
  }
  .client-input {
    border: none;
    &:focus,
    .ant-input-focused {
      border-color: transparent;
      box-shadow: none;
      border-right-width: 1px;
      outline: 0;
    }
  }
  .ant-table-cell {
    cursor: default !important;
  }

  .ant-table-expanded-row-level-1 > td {
    border-color: transparent;
    border-bottom-color: #e21c21;
  }

  .heading-text {
    text-transform: uppercase;
    color: rgb(56, 56, 56);
    font-family: 'Open Sans', Arial;
    font-weight: 700;
    font-style: normal;
    font-size: 11px;
    margin-bottom: 6px;
  }

  .switch-parent-div {
    color: #aaaaaa;
    font-family: 'Open Sans', Arial;
    font-weight: 600;
    font-size: 13px;
    .off {
      color: rgb(226, 15, 0);
    }
    .on {
      color: #3f9d2f;
    }
    .ant-switch-checked {
      background-color: #e2e2e2;
      .ant-switch-handle::before {
        background-color: #3f9d2f !important;
      }
    }
    .ant-switch {
      width: 50px;
      height: 20px;
      .ant-switch-handle {
        width: 15px;
        height: 15px;
      }
      .ant-switch-handle::before {
        background-color: rgb(226, 15, 0);
      }
    }
  }
}
.project-button {
  width: 88px !important;
  height: 30px !important;
  line-height: 1px !important;
}
.editing-row {
  background: transparent !important;
}
.editing-row + td {
  background: transparent !important;
  .custom-icon {
    fill: #f55056 !important;
  }
}
.permissions {
  background-color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #e2e2e2;
  border-radius: 2px;
  margin-left: 70px;
  margin-right: -45px;
  background-size: cover;
  width: 100px;
  opacity: 1;
  position: relative;
  .ant-row {
    justify-content: start;
    @media screen and (max-width: 1400px) {
      justify-content: flex-start;
      margin-left: 20px;
      margin-right: 0px;
      padding-left: 1px;
    }
    @media screen and (min-width: 768px) {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}
.setting-permissions {
  margin-left: 70px;
  @media screen and (min-width: 1600px) {
    margin-left: 62px;
    margin-right: 0px;
    row-gap: 20px;
  }
  @media screen and (max-width: 1599px) {
    margin-left: 40px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
    row-gap: 20px;
  }
  @media screen and (max-width: 1199px) {
    margin-left: 0px;
    margin-right: 0px;
    margin-left: 24px;
  }
  @media screen and (max-width: 991px) {
    margin-left: 0px;
    margin-right: 0px;
    row-gap: 20px;
  }
}

.general-permission-upper-div {
  margin-left: 62px;
  @media screen and (min-width: 1600px) {
    margin-left: 62px;
  }
  @media screen and (max-width: 1599px) {
    margin-left: 40px;
  }
  // @media screen and (max-width: 992px) {
  //   margin-left: 40px;
  // }
  @media screen and (max-width: 1199px) {
    margin-left: 40px;
  }
  @media screen and (max-width: 991px) {
    margin-left: 0px;
  }
}

.permission-col {
  .name-col {
    text-transform: capitalize;
  }
}

@primary-color: #e21c21;