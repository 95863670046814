.pillar-section-parent-row {
  // background-color: aqua;
  margin: 30px 0px;
  .border-right {
    border-right: 1px solid rgba(226, 226, 226, 1);
  }
  label {
    letter-spacing: 0px;
    font-family: 'Open Sans', Arial;
    font-weight: 700;
    font-style: normal;
    // height: 13px;
    text-transform: uppercase;
    // padding-left: 10px;

    line-height: 14px;
    letter-spacing: 0px;
    color: rgba(35, 31, 32, 1);
    font-family: 'Open Sans', Arial;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    text-decoration: underline;
  }
  .status-select {
    width: 127px;
    height: 27px;
    .dot {
      height: 13px;
      width: 13px;
      border-radius: 50%;
      display: inline-block;
      border-color: rgba(209, 209, 209, 1);
      margin-right: 10px;
      margin-top: 4px;
    }
    .good {
      background-color: rgba(67, 160, 71, 1);
    }
    .medium {
      background-color: rgba(251, 140, 0, 1);
    }
    .poor {
      background-color: rgba(249, 51, 36, 1);
    }
  }
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 24px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    height: 27px !important;
    padding-left: 11px !important;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 11px !important;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 26px;
  }

  .ant-checkbox-wrapper {
    flex-direction: row-reverse;
    width: 95%;
    justify-content: space-between;
    &::after {
      content: none;
    }
    .label {
      line-height: 14px;
      letter-spacing: 0px;
      color: rgba(35, 31, 32, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 500;
      font-style: normal;
      font-size: 12px;
      display: inline-flex;
      gap: 20px;
      width: 100%;
      align-items: center;
      span {
        min-width: 50px;
      }
      .ant-input {
        height: 20px;
        // margin-top: 10px;
      }
    }
    .question-label {
      text-transform: none;
    }
  }

  .ant-checkbox + span {
    padding-left: 0px;
    width: 90%;
  }
  .attach-parent-div {
    height: 16px;
    color: rgba(226, 28, 33, 1);
    svg {
      fill: rgba(226, 28, 33, 1);
    }
  }
  .pillar2-div {
    margin-bottom: 30px;
  }
  .ant-input-number {
    min-width: 40px;
    height: 20px;
    margin-left: 10px;
    line-height: 1px;
    .ant-input-number-handler-wrap {
      width: 0px;
    }
    .ant-input-number-input {
      height: 14px;
      padding: 0px 7px;
    }
  }
}

.status-select-dropdown {
  .ant-select-item .ant-select-item-option-content {
    line-height: 16px;
    letter-spacing: 0px;
    color: rgb(56, 56, 56);
    font-family: 'Open Sans', Arial;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    line-height: 1.5;
  }
  .dot {
    height: 13px;
    width: 13px;
    border-radius: 50%;
    display: inline-block;
    border-color: rgba(209, 209, 209, 1);
    margin-right: 10px;
    margin-top: 4px;
  }
  .good {
    background-color: rgba(67, 160, 71, 1);
  }
  .medium {
    background-color: rgba(251, 140, 0, 1);
  }
  .poor {
    background-color: rgba(249, 51, 36, 1);
  }
}

@primary-color: #e21c21;