.production-manager-parent-row {
  .heading-col {
    padding: 2px 25px 8px 12px;
    color: #e21c21;
  }
  .add-user-btn {
    border: 1px solid #e21c21;
    width: 100px;
    color: #e21c21;
    svg {
      fill: #e21c21;
    }
  }
}
.texts {
  line-height: 12px;
  letter-spacing: 0px;
  color: rgba(113, 113, 113, 1);
  font-family: "Open Sans", Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  text-align: center;
}
.text {
  font-weight: 400 !important;
  font-style: normal !important;
}

.status-selector {
  min-width: 150px !important;
}

.doc-type-selector {
  min-width: 230px !important;
  max-width: max-content;
}

@primary-color: #e21c21;