.export-projects-popover {
  .ant-popover-content {
    border: 1px solid rgba(226, 28, 33, 1);
    border-radius: 2px;
    .ant-popover-arrow-content {
      box-shadow: 1px 1px 1px rgba(226, 28, 33, 1);
    }
    .ant-popover-inner {
      border-radius: 2px;
      height: 150px;
      width: 304px;
      .title {
        line-height: 16px;
        letter-spacing: 0px;
        color: rgb(226, 28, 33);
        font-family: "Open Sans", Arial;
        font-weight: 600;
        font-style: normal;
        font-size: 13px;
        text-align: left;
      }
      .ant-popover-inner-content {
        .invalid-text {
          background-color: rgb(247, 247, 247);
        }
        .valid-text {
          background-color: #fff;
        }
        .valid-text-btn {
          background-color: rgba(226, 28, 33, 1);
          color: white;
        }
        .invalid-text-btn {
          background-color: #fff;
          color: rgba(226, 28, 33, 1);
        }
        textarea[readonly] {
          border-color: transparent;
        }
      }
    }
  }
  .ant-popover-title {
    padding: 12px 0px 8px 0px;
    margin: 0px 12px 0px 12px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    border-bottom: 1px solid rgba(226, 28, 33, 1);
    svg {
      font-size: 14px;
      fill: rgba(226, 28, 33, 1);
    }
  }
  .ant-popover-inner-content {
    .parent-div {
      margin-bottom: 0px;
      .success-row {
        .success-text {
          line-height: 16px;
          letter-spacing: 0px;
          color: rgb(226, 28, 33);
          font-family: "Open Sans", Arial;
          font-weight: 600;
          font-style: italic;
          font-size: 13px;
          text-align: left;
        }
        .close-btn {
          width: 80px;
          height: 24px;
          color: rgb(226, 28, 33);
          border: 1px solid rgb(226, 28, 33);
          margin: 0px 35%;
          font-size: 13px;
        }
      }
    }
    .export-btn {
      line-height: 1;
      color: #fff;
      background: rgba(226, 28, 33, 1);
      border-color: rgba(226, 28, 33, 1);
      min-width: 135px;
      height: 32px;
      margin-top: 20px;
      svg {
        // height: 14px;
        width: 14px;
        path {
          fill: #fff;
        }
      }
      span {
        padding-left: 5px;
      }
    }
  }
}

@primary-color: #e21c21;