.page-top-div {
  .add-activity-btn
  // .ant-btn[disabled],
  // .ant-btn[disabled]:hover,
  // .ant-btn[disabled]:focus,
  // .ant-btn[disabled]:active
  {
    width: 137px;
    border: 1px solid #e21c21;
    background-color: white;
    opacity: 1;
    filter: none;
    line-height: 16px;
    letter-spacing: 0px;
    color: rgba(226, 28, 33, 1);
    font-family: 'Open Sans', Arial;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    svg {
      fill: #e21c21;
    }
  }
}
.ant-input:hover {
  border-color: #f72f2f !important;
  border-right-width: 1px;
  box-shadow: 0 0 0 2px rgbi(235 7 7 / 20%) !important;
}
.production-manager-parent-row .heading-col .heading-span {
  line-height: 17px;
  letter-spacing: 0px;
  color: rgba(226, 28, 33, 1);
  font-family: 'Open Sans', Arial;
  font-weight: 700;
  text-transform: uppercase;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 5px !important;
}
.ant-btn > .anticon {
  line-height: 2;
}

.input {
  width: 20%;
  text-align: 'inherit';
}

.default-doc-field-table {
  margin-bottom: 20px;
  .action-col {
    vertical-align: bottom;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid transparent;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .special-table .ant-table-tbody > tr > td.ant-table-cell-row-hover,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: transparent;
    color: black;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td svg,
  .special-table .ant-table-tbody > tr > td.ant-table-cell-row-hover svg,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover svg {
    fill: #f55056;
  }

  .ant-table-tbody > tr > td {
    padding: 12px 16px 8px 16px;
  }
}
.field-text {
  font-weight: 600;
  margin-top: 0.3em !important;
}

@primary-color: #e21c21;