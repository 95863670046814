@import '~antd/dist/antd.less';

.ant-tooltip {
  display: none;
}

.ant-select-item-option-content {
  white-space: pre-line;
}

.page-top-div {
  margin-top: 25px;
  .text-span {
    font-size: 16px;
    color: #e21c21;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0px;
    line-height: 19px;
  }
}

.special-table-header-text,
.ant-table-column-title {
  min-height: 25px; //40px
  font-family: 'Open Sans', Arial;
  font-size: 11px;
  color: #231f20;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 6px;
  text-transform: uppercase;
  line-height: 13px;
  .ant-input-number {
    width: 100%;
    height: 24px;
    .ant-input-number-handler,
    .ant-input-number-handler-wrap {
      width: 0px;
    }
    input {
      height: 24px;
      padding: 0px 6px;
      line-height: 24px;
      min-width: 40px;
    }
  }
}

.special-table-header-no-padding {
  min-height: 25px; //40px
  font-family: 'Open Sans', Arial;
  font-size: 11px;
  color: #231f20;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 6px;
  text-transform: uppercase;
  line-height: 13px;
  white-space: nowrap;
  text-align: left;
}

.ant-table-column-title-without-input {
  min-height: 25px;
  font-family: 'Open Sans', Arial;
  font-size: 11px;
  color: #231f20;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 5px;
  div {
    justify-content: center;
  }
}

.special-table .ant-table-column-sorters {
  margin-bottom: 5px; // 20px
}

.special-table-header-text-center {
  min-height: 25px; //40px
  font-family: 'Open Sans', Arial;
  font-size: 11px;
  font-weight: 700;
  color: #231f20;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.activity-type-table {
  .ant-table-measure-row {
    display: none;
  }
  .ant-table-tbody > tr > td {
    padding: 16px 16px 16px 16px !important;
    input.ant-input {
      height: inherit;
    }
    .ant-picker-large {
      padding: 6.5px 11px 6.5px 0px;
    }
    .ant-input-lg {
      padding: 4px 11px 4px 0px;
    }
    textarea.ant-input {
      max-height: 46px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 35px;
      padding-left: 0px;
    }
    // .ant-input {
    //   text-align: left;
    // }
    .ant-picker:not(.ant-picker-range) .ant-picker-input input,
    input.ant-input,
    .ant-picker-large,
    .ant-input-lg,
    .ant-select-selection-item,
    textarea.ant-input {
      font-size: 13px;
      font-family: 'Open Sans';
      color: rgb(35 31 32 / 1); // 50%
      padding-left: 0px !important;
      text-align: left;
    }
  }
}

.special-table .ant-table-tbody > tr > td {
  //   padding: 12px 16px 11px 16px;
  padding: 23px 16px 16px 16px;
}

// .product-manager-table .ant-table-tbody > tr > td {
//   padding: 7px 16px 6px 16px;
// }

.special-table .custom-expanded-table .ant-table-tbody > tr > td {
  padding: 5px 16px 4px 16px;
}

.special-table .custom-expanded-table .ant-table-thead > tr th {
  padding: 0px 2px;
}

.special-table .ant-table-tbody > tr > td {
  // border-bottom-color: #e2e2e2;
  font-size: 12px;
  color: rgba(7, 7, 7, 1);
  line-height: 16px;
  .custom-icon {
    fill: #f55056;
    width: 16px;
    height: 16px;
    font-size: 16px;
  }
  .disable-icon {
    cursor: not-allowed !important;
  }
}

.special-table .ant-table-tbody > tr > td .rotate-icon {
  transform-origin: 9px 30px;
  transform: rotate(320deg);
}

.table-main-div-holder {
  .special-table .ant-table-column-sorter {
    padding-top: 32px !important;
  }
}

.special-table {
  cursor: pointer;
  //   padding-left: 8px;
  //   padding-bottom: 20px;
  padding: 0px 15px 20px;
  //   padding-bottom: 20px;
  //   min-height: 748px;
  border: 8px;
  .ant-table-column-sorter {
    padding-top: 25px;
  }

  .custom-categories-crud-table {
    border-radius: 8px !important;
  }
  .select-parent-div {
    width: 100%;
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 24px;
    }
  }

  .ant-table-cell-ellipsis .ant-table-column-title {
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 24px;
    }
  }

  input.ant-input
  // .ant-select-selector
  {
    // border-radius: 8%;
    height: 25px;
  }
  .ant-table-thead > tr {
    th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan])::before {
      display: none;
    }
    th {
      border-bottom-color: rgba(0, 0, 0, 1);
      padding: 12px 2px;
      background-color: #ffffff;
    }
    .top {
      width: 100%;
    }
  }

  tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row:hover > td {
    background: #ffffff;
  }

  // .ant-table-tbody > tr.editable-row:hover > td,
  // .ant-table-tbody > tr.editable-row > td,
  // .ant-table-tbody > tr.editable-row:hover > td.ant-table-cell-row-hover,
  // .ant-table-tbody > tr.editable-row > td.ant-table-cell-row-hover {
  //   background: #fff;
  //   color: #eb078e;
  //   .ant-table-row-expand-icon {
  //     // color: #eb078e;
  //   }
  //   .rotate-icon {
  //     fill: #231f20;
  //   }
  //   .custom-icon {
  //     fill: #eb078e;
  //   }
  //   .attach-parent-div {
  //     color: #231f20;
  //   }
  // }

  .ant-table-tbody > tr.ant-table-row:hover > .first-level-status-td {
    .ant-tag-has-color {
      background-color: rgb(148 212 240) !important;
    }
  }

  .ant-table-tbody > .ant-table-row > .ant-table-cell-row-hover {
    .status-tag {
      background-color: rgb(148 212 240) !important;
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: rgba(255, 186, 0, 0.8);
    color: rgb(7, 7, 7);
    .ant-table-row-expand-icon {
      color: #e21c21;
    }
    .rotate-icon {
      fill: #e21c21;
    }
    svg {
      fill: #e21c21;
    }
    .attach-parent-div {
      color: #e21c21;
    }
    // .border-less-input,
    .border-less-input::placeholder {
      color: #fff !important;
    }
    // .status-tag {
    //   background-color: #fff !important;
    // }
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #fff;
  }

  .ant-table-expanded-row {
    td {
      border-bottom: 1px solid rgb(85, 85, 85);
    }
    .table-second-level .ant-table-tbody > tr.ant-table-row:hover > td,
    .table-second-level .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: #fff;
      color: rgba(7, 7, 7, 1);
      svg {
        fill: #e21c21;
      }
      .format-div svg {
        fill: rgba(7, 7, 7, 1);
      }
    }
    .custom-expanded-table .ant-table {
      margin: -16px -16px -16px 0px !important;
      .ant-table-column-title {
        flex: revert;
        .select-parent-div {
          width: 100%;
        }
      }
    }
    .ant-table-column-sorter {
      padding-top: 4px;
    }
    .table-second-level {
      .ant-table-tbody {
        tr .first-level-status-td {
          padding: 16px 0px 16px 12px;
        }
        .format-div {
          color: rgba(7, 7, 7, 1);
          span {
            color: #e21c21;
          }
        }
        td {
          border: 1px solid transparent;
          border-bottom-color: lightgray;
          padding: 16px 16px 16px 16px;
          .table-third-level {
            border-bottom: 1px solid #e21c21;
          }
        }
      }
      .ant-table-expanded-row {
        td {
          border-bottom: 1px solid #e21c21;
        }
      }
      .table-third-level {
        .ant-table-tbody {
          td {
            // border: 1px solid transparent;
            border-bottom: 1px solid lightgray;
            padding: 16px 16px 16px 16px;
          }
        }
        .ant-table-expanded-row {
          td {
            border-bottom: 1px solid #e21c21;
          }
        }
      }
      .select-align-center {
        .ant-select-selection-overflow {
          justify-content: center;
          .ant-select-selection-item {
            background-color: transparent;
            border: none;
          }
        }
      }
    }
    .without-search-header {
      .ant-table-thead th.ant-table-column-has-sorters {
        .ant-table-column-sorters {
          display: inline-flex;
          .ant-table-column-sorter-full {
            padding-top: 1px;
          }
        }
      }
    }
  }

  .expand-parent td + svg {
    background-color: rgba(255, 186, 0, 0.8);
    color: #fff !important;
    border-bottom: none;
  }
  .ant-table-expanded-row-level-1 > td {
    border: 1px solid rgba(255, 186, 0, 0.8);
  }

  .ant-table-expanded-row > td {
    // border-bottom: 1px solid #eb078e !important;
  }

  .fourth-level-expanded-row {
    .ant-table-expanded-row > td {
      border-bottom: 1px solid transparent;
    }
  }

  .quatity-form-input {
    margin-bottom: 0px;
    input,
    .ant-input-lg {
      font-size: 13px;
      height: 32px;
    }
    .ant-form-item-control {
      .ant-form-item-explain {
        display: none;
      }
    }
  }

  table > tbody > tr.expand-parent {
    background-color: rgba(255, 186, 0, 0.8);
    color: #fff;
    td {
      background-color: rgba(255, 186, 0, 0.8);
      color: #fff;
      .custom-icon,
      .rotate-icon {
        fill: #fff;
      }
      .format-div {
        color: #fff !important;
        svg {
          fill: #fff !important;
        }
      }
      // .status-tag {
      //   background-color: #fff !important;
      // }
    }
    .first-level-status-td {
      // background-color: white !important;
    }
    .first-level-status-td {
      // padding: 16px 0px 16px 12px;
      .ant-tag-has-color {
        background-color: rgb(148 212 240) !important;
      }
    }
  }

  table > tbody > tr.expand-parent:hover {
    background-color: rgba(255, 186, 0, 0.8) !important;
    color: #fff !important;
    td {
      background-color: rgba(255, 186, 0, 0.8) !important;
      color: #fff !important;
      .custom-icon,
      .rotate-icon {
        fill: #fff !important;
      }
      .format-div {
        color: #fff !important;
        svg {
          fill: #fff !important;
        }
      }
    }
  }

  .ant-spin-nested-loading > div > .ant-spin {
    max-height: 100%;
  }
}

.custom-btn1,
.custom-btn1:focus,
.custom-btn1:hover,
.custom-btn1:active {
  background-color: #e21c21;
  span {
    color: #fff;
    font-size: 13px;
  }
  opacity: 0.4;
}
.ant-select-dropdown {
  border: 1px solid #d9d9d9;
  padding-top: 0px !important;
  .custom-select-dropdown {
    padding-left: 15px;
    .ant-divider {
      width: 90%;
      min-width: 90%;
      margin: 12px 0;
      border-top: 1px solid #d9d9d9;
    }
    .ant-checkbox-group-item {
      margin: 5px 0px;
      color: rgba(7, 7, 7, 1);
      font-size: 13px;
      font-weight: 400;
      display: flex;
    }
    .ant-check {
      border: 1px solid #606060;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #fff;
      border-color: #606060;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      position: absolute;
      display: table;
      border: 2px solid #606060;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: ' ';
    }
  }
}

.department-emails-table,
.activity-type-table {
  .ant-form-item-explain {
    display: none;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    // background: #fff;
    // color: #231f20;
    // svg {
    //   fill: #eb078e !important;
    // }
    // .activities-attach {
    //   fill: #231f20 !important;
    // }
  }
  .ant-input:focus,
  .ant-input-focused {
    border-color: #f72f2f;
    box-shadow: 0 0 0 2px rgba(255, 164, 164, 0.834);
    border-right-width: 1px;
    outline: 0;
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #fff;
  }
}

.switch-parent-div {
  color: #aaaaaa;
  font-family: 'Open Sans', Arial;
  font-weight: 600;
  font-size: 13px;
  .off {
    color: rgb(226, 15, 0);
  }
  .on {
    color: #3f9d2f;
  }
  .ant-switch-checked {
    background-color: #e2e2e2;
    .ant-switch-handle::before {
      background-color: #3f9d2f !important;
    }
  }
  .ant-switch {
    width: 50px;
    height: 20px;
    .ant-switch-handle {
      width: 15px;
      height: 15px;
    }
    .ant-switch-handle::before {
      background-color: rgb(226, 15, 0);
    }
  }
}

.ant-select-item:hover {
  background-color: #e21c21;
  color: #fff;
}

.custom-blue-btn,
.custom-blue-btn:hover,
.custom-blue-btn:focus {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  background: #fff;
  border: 1px solid #2660a4;
  // height: 40px;
  color: #2660a4;
  svg {
    fill: #2660a4;
  }
  .anticon + span {
    margin-left: 2px;
  }
}

.activities-popconfirm {
  .ant-popover-buttons {
    text-align: center;
    direction: rtl;
    .ant-btn {
      margin: 0px 8px;
    }
  }
}

.activities-timePicker {
  border-radius: 0px !important;
  input {
    padding-left: 0px !important;
  }
  .ant-picker-input::after {
    background-image: none;
    content: none;
  }
  background: #fff !important;
  border-color: #c6c6c6;
  cursor: not-allowed;
  box-shadow: 0 0 0 0px rgb(235 7 142 / 20%);
}

.activities-time-picker {
  .ant-picker-time-panel {
    width: 112px;
  }
  .ant-picker:not(.ant-picker-range) .ant-picker-input input {
    color: #171c26;
    padding-left: 0px;
    text-align: center;
  }
}

.attach-parent-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.activities-attach-popover {
  .ant-popover-inner-content {
    max-width: 260px;
    min-width: 200px;
    border: 1px solid #e21c21;
    .close-icon-div {
      display: flex;
      justify-content: end;
      margin-bottom: 20px;
    }
    .rotate-icon {
      transform-origin: 9px 30px;
      transform: rotate(320deg);
    }
    .name-span {
      text-decoration: underline;
      word-break: break-word;
      cursor: pointer;
    }
    .del-icon {
      cursor: pointer;
    }
    .add-attach-btn > .anticon + span {
      margin-left: 4px;
    }
    .add-attach-btn {
      margin: 5px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 113px;
      border: 1px solid #e21c21;
      // height: 40px;
      height: 26px;
      padding: 4px 6px;
      color: #e21c21;
      svg {
        fill: #e21c21;
      }
    }
  }
}

.add-comment-popover {
  .ant-popover-content {
    border: 1px solid #e21c21;
    border-radius: 16px;
    .ant-popover-arrow-content {
      box-shadow: 1px 1px 1px #e21c21;
    }
    .ant-popover-inner {
      border-radius: 16px;
      .title {
        padding-left: 11px;
      }
      .ant-popover-inner-content {
        .invalid-text {
          background-color: rgb(247, 247, 247);
        }
        .valid-text {
          background-color: #fff;
        }
        .valid-text-btn {
          background-color: #e21c21;
          color: white;
        }
        .invalid-text-btn {
          background-color: #fff;
          color: #e21c21;
        }
        textarea[readonly] {
          border-color: transparent;
        }
      }
    }
  }
  .ant-popover-title {
    padding: 12px 12px 0px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    border-bottom: 1px solid transparent;
    .title {
      line-height: 16px;
      color: rgba(35, 31, 32, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 600;
      font-size: 13px;
    }
    svg {
      font-size: 14px;
    }
  }
  .ant-popover-inner-content {
    .ant-btn {
      line-height: 1;
      color: #e21c21;
      border-color: #e21c21;
      background: #fff;
      width: 80px;
      height: 24px;
    }
  }
}

.recorded-time-popover {
  .ant-popover-content {
    border: 1px solid #e21c21;
    .ant-popover-arrow-content {
      box-shadow: 1px 1px 1px #e21c21;
    }
  }
  .ant-popover-title {
    padding: 12px 12px 0px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    border-bottom: 1px solid transparent;
  }
}

.activities-time-picker {
  .ant-picker-input input {
    color: #171c26;
    padding-left: 12px !important;
  }
  .ant-picker-input::after {
    content: none;
  }
}

.prod-due-date .ant-picker-input input {
  padding-left: 0px !important;
}

.ant-input::placeholder,
input::placeholder,
.ant-select-selection-placeholder {
  color: #231f2080 !important; // red
  font-size: 13px !important;
  font-weight: normal !important;
  font-family: 'Open Sans';
  // line-height: 1 !important;
  // text-transform:capitalize;
}

.border-less-input {
  border: none;
  padding: 4px 0px;
  text-align: center;
  background-color: transparent;
  color: #231f2080;
}

.multi-select-custom {
  .rc-virtual-list-holder {
    .ant-select-item {
      padding: 8px 2px 5px 10px;
    }
  }

  .rc-virtual-list-holder-inner > :first-child[label='Select All'] {
    border-bottom: 1px solid grey;
    padding: 12px 2px 12px 10px;
    .ant-select-item-option-content {
      .select-all-div {
        display: flex;
        justify-content: space-between;
        div {
          font-size: 13px;
          color: rgba(7, 7, 7, 1);
        }
      }
      display: flex;
      justify-content: space-between;

      .cross-icon svg {
        fill: #231f20 !important;
        width: 16px;
        height: 16px;
      }
    }
  }
  .rc-virtual-list-holder-inner > :nth-child(2) {
    // padding: 12px 2px 12px 10px;
    padding: 8px 2px 5px 10px;
    .ant-checkbox-wrapper {
      .ant-checkbox + span {
        padding-right: 2px;
      }
    }
  }

  .done-btn {
    border: 1px solid #e21c21;
    color: #e21c21;
    border-radius: 2px;
    width: 80px;
    line-height: 1;
    height: 20px;
    padding: 0px 14px;
    margin: 10px auto;
  }
}

.closee {
  right: 10px;
  top: 29px;
  position: absolute;
  z-index: 1;
}

.ant-layout-content.layout-content.site-layout-background.custom-textarea {
  position: relative !important;
}

.select-dropdown-custom {
  min-width: fit-content !important;
  .ant-select-item {
    .ant-select-item-option {
      color: rgb(35, 31, 32);
      font-family: 'Open Sans', Arial;
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
    }
    .ant-select-item-option-content {
      font-size: 13px;
    }
    .ant-select-item-option-selected {
      color: white;
    }
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #e21c21;
    font-weight: 400;
  }
}

.doc-control-parent-row {
  margin-top: 20px;
  box-shadow: 1px 1px 4px 1px rgba(56, 56, 56, 0.4) !important;
  background-color: rgb(255, 255, 255);
  padding: 10px 15px;
  border-radius: 8px;
  margin-bottom: 30px;
  width: 100%;
  .heading-col {
    border-bottom: 1px solid rgba(226, 28, 33, 1);
    padding: 10px 25px 5px 12px;
    @media screen and (max-width: 1099px) {
      padding: 10px 15px 5px 12px;
    }
    .heading-span {
      line-height: 17px;
      font-family: 'Open Sans', Arial;
      font-weight: 700;
      text-transform: uppercase;
      color: #070707;
      font-size: 14px;
      .date-div {
        font-size: 13px;
        text-transform: capitalize;
        line-height: 16px;
        letter-spacing: 0px;
        color: rgba(7, 7, 7, 1);
        font-family: 'Open Sans', Arial;
        font-weight: 600;
        font-style: normal;
      }
    }
    .add-doc-btn,
    .ant-btn[disabled],
    .ant-btn[disabled]:hover,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active {
      // width: 125px;
      height: 28px;
      border: 1px solid #e21c21;
      border-radius: 2px;
      background-color: white;
      opacity: 1;
      filter: none;
      line-height: 16px;
      letter-spacing: 0px;
      color: rgba(226, 28, 33, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 600;
      font-style: normal;
      font-size: 13px;
      padding: 2px 12px;
      text-transform: uppercase;
      svg {
        fill: #e21c21;
      }
    }
    .site-btn,
    .class-btn {
      width: 93px;
    }
    .ant-btn[disabled] {
      cursor: not-allowed !important;
    }
    .ant-space {
      display: flex;
      align-items: flex-start;
      .ant-space-item {
        svg {
          width: 22px;
          height: 22px;
          font-size: 22px;
          cursor: pointer;
        }
        .patch-icon {
          width: 22px;
          height: 22px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            cursor: pointer;
          }
        }
      }
    }
  }
  .client-btn {
    height: 30px;
    width: 150px;
    padding: 2px 15px;
    text-transform: uppercase;
    @media screen and (max-width: 1099px) {
      width: 130px;
      padding: 2px 5px;
    }
  }
  .selected {
    opacity: 1;
  }
  .sales-rep-btn {
    margin-left: 25px;
    height: 30px;
    width: 150px;
    padding: 2px 15px;
    text-transform: uppercase;
    @media screen and (max-width: 1099px) {
      width: 130px;
      padding: 2px 5px;
      margin-left: 10px;
    }
  }
  .graph-section {
    min-height: 50vh;
    padding: 15px 20px;
    .spinner-div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    .production-by-client-div {
      display: flex;
      align-items: center;
      gap: 25px;
      .sort-icons {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
    .graph-text-span {
      color: rgba(13, 40, 67, 1);
      font-family: 'Open Sans', Arial;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      line-height: 17px;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .graph-label-with-text {
      display: flex;
      gap: 10px;
      .actual-div,
      .target-div {
        display: flex;
        gap: 5px;
        align-items: center;
        color: #231f20;
        font-size: 13px;
        .blue-dot,
        .red-dot,
        .small-format-dot,
        .large-format-dot,
        .ais-dot,
        .emails-dot,
        .proportional-dot {
          height: 13px;
          width: 13px;
          border-radius: 50%;
          display: inline-block;
        }
        .blue-dot {
          background-color: #29aae1;
        }
        .small-format-dot {
          background-color: #690277;
        }
        .large-format-dot {
          background-color: #188bf6;
        }
        .ais-dot {
          background-color: #a1d1ff;
        }
        .proportional-dot {
          background-color: #7450ba;
        }
        .red-dot {
          background-color: #e21c21;
        }
        .emails-dot {
          background-color: #a0a6d8;
        }
        .dot {
          height: 13px;
          width: 13px;
          border-radius: 50%;
          display: inline-block;
        }
      }
    }
    .ordered-compl-label-with-text {
      display: flex;
      gap: 10px;
      margin-top: 10px;
      .dot-parent-div {
        // display: flex;
        // gap: 5px;
        // align-items: center;
        color: #231f20;
        font-size: 13px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block; // block
        span {
          height: 13px;
          width: 13px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 5px;
        }
        .modular-actual {
          background-color: #ffc732;
        }
        .modular-target {
          background-color: #e31c21;
        }
        .mass-actual {
          background-color: #e56c37;
        }
        .mass-target {
          background-color: #d74550;
        }
      }
    }
  }
  .ims-doc-filter-section {
    margin: 20px 0px;
    padding: 0px 15px;
    border: 1px solid rgba(85, 85, 85, 1);
    border-radius: 3px;
    .sub-sections-filter-col,
    .sub-sections-filter-col-collapsed,
    .sub-sections-col,
    .sub-sections-col-collapsed {
      margin: 15px 0px;
    }
    .sections-row {
      padding: 0px 20px;

      .sub-sections-filter-col {
        width: 14%;
        // @media screen and (max-width: 1299px) {
        //   padding: 0px 10px !important;
        //   width: 100%;
        // }
        // @media screen and (max-width: 1199px) {
        //   padding: 0px 10px !important;
        //   width: 50%;
        // }
      }

      .sub-sections-filter-col-collapsed {
        width: 14%;
        // @media screen and (max-width: 1199px) {
        //   padding: 0px 10px !important;
        //   width: 100%;
        // }
        // @media screen and (max-width: 900px) {
        //   padding: 0px 20px !important;
        //   width: 50%;
        // }
      }

      .sub-sections-col {
        width: 43%;
        // @media screen and (max-width: 1399px) {
        //   padding: 0px 0px 0px 5px !important;
        // }
        // @media screen and (max-width: 1299px) {
        //   padding: 0px 0px 0px 5px !important;
        //   width: 33.33%;
        // }
        @media screen and (max-width: 1199px) {
          padding: 0px 0px 0px 5px !important;
          // width: 50%;
        }
      }

      .sub-sections-col-collapsed {
        width: 43%;

        @media screen and (max-width: 991px) {
          padding: 0px 0px 0px 10px !important;
          // width: 33.33%;
        }
        // @media screen and (max-width: 900px) {
        //   padding: 0px 20px 0px 20px !important;
        //   width: 50%;
        // }
      }

      .sub-sections-col,
      .sub-sections-col-collapsed {
        border-right: 1px solid rgba(170, 170, 170, 1);

        .label-div {
          span {
            line-height: 16px;
            letter-spacing: 0px;
            color: rgba(56, 56, 56, 1);
            font-family: 'Open Sans', Arial;
            font-weight: 700;
            font-style: normal;
            font-size: 13px;
            text-align: left;
          }
        }
        .production-manager-parent-row {
          .heading-col {
            padding: 2px 25px 8px 12px;
            color: #e21c21;
          }
          .add-template-btn {
            border: 1px solid #e21c21;
            width: 100px;
            color: #e21c21;
            svg {
              fill: #e21c21;
            }
          }
        }
        .production-template-parent-row .heading-col .heading-template {
          line-height: 17px;
          letter-spacing: 0px;
          color: rgb(0, 0, 0);
          font-family: 'Open Sans', Arial;
          font-weight: 700;
          text-transform: uppercase;
        }

        .document-type-section {
          .ant-form-vertical .ant-form-item-label,
          .ant-col-24.ant-form-item-label,
          .ant-col-xl-24.ant-form-item-label {
            padding: 0px;
          }
          .doc-type-number-form-item {
            margin-bottom: 10px;
            .ant-form-item-row {
              gap: 3px;
            }
          }
          .ant-col-24.ant-form-item-label {
            line-height: 1;
            white-space: nowrap;
            label {
              height: 16px !important;
              margin-bottom: 1px;
            }
          }
          .ant-form-item > .ant-form-item-row {
            flex-flow: column !important;
            .ant-form-item-label {
              text-align: start !important;
            }
          }
          .ant-form-item-has-error .ant-picker:not(.ant-picker-disabled) {
            border-radius: 0px;
            box-shadow: none;
            border: 1px solid #ff4d4f;
          }

          .ant-picker-input input {
            padding-left: 2px !important;
          }

          .ant-picker.ant-picker-disabled {
            background: #fff;
            border-color: transparent;
            cursor: text;
            .ant-picker-input::after {
              display: none;
            }
          }
          .flex-column {
            flex-flow: column !important;
          }
          .ant-radio-wrapper:hover .ant-radio,
          .ant-radio:hover .ant-radio-inner,
          .ant-radio-input:focus + .ant-radio-inner {
            border-color: #383838;
          }
          .ant-radio-input:focus + .ant-radio-inner {
            box-shadow: none;
          }
          .ant-radio-checked {
            .ant-radio-inner {
              border-color: #383838;
              &::after {
                background-color: #383838;
              }
            }

            &::after {
              border: 1px solid #383838;
            }
          }

          .ant-checkbox-checked {
            &::after {
              border: 1px solid #383838;
            }
            .ant-checkbox-inner {
              background-color: #383838;
              border-color: #383838;
            }
          }
          .ant-checkbox-wrapper:hover .ant-checkbox-inner,
          .ant-checkbox:hover .ant-checkbox-inner,
          .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: #383838;
          }
          .template-type {
            display: inline-block;
            .projects {
              margin-left: 25px;
            }
          }
          .save-btn {
            background-color: #e21c21;
            border-color: #e21c21;
            text-transform: uppercase;
          }
          .field-button {
            width: 10%;
            color: #e21c21;
            border-color: #e21c21;
          }
          .project-radio {
            text-transform: capitalize !important;
          }
        }

        .text-document-template {
          line-height: 2.5715;
          padding-left: 10px;
          color: rgba(56, 56, 56, 1);
          font-family: 'Open Sans', Arial;
          font-weight: 400;
          font-size: 10px;
        }
        .text-complete-template {
          line-height: 16px;
          letter-spacing: 0px;
          color: rgb(56, 56, 56);
          font-family: 'Open Sans', Arial;
          font-weight: 600 !important;
          text-align: left;
          font-size: 10px !important;
          margin-left: 10px;
          font-style: normal;
          width: auto;
        }
        .div-text {
          padding-bottom: 10px;
          .checkbox-div {
            label {
              text-transform: capitalize;
              color: rgba(56, 56, 56, 1);
              font-family: 'Open Sans', Arial;
              font-weight: 600;
              font-size: 13px;
            }
          }
        }

        .doc-type-table {
          .ant-table-column-title {
            .ant-input,
            .ant-select-selector {
              border-color: #c6c6c6;
            }
            .ant-select-selector,
            .ant-select-single:not(.ant-select-customize-input)
              .ant-select-selector
              .ant-select-selection-search-input {
              height: 25px;
            }
          }
        }
        .li-div {
          .ant-select-single .ant-select-selector .ant-select-selection-item,
          .ant-select-single
            .ant-select-selector
            .ant-select-selection-placeholder {
            padding: 0;
            line-height: 22px;
            transition: all 0.3s;
            font-size: 13px;
          }
        }

        .custom-fields-parent-div {
          .label-div {
            margin-top: 10px;
          }
          label {
            line-height: 13px;
            letter-spacing: 0px;
            color: rgba(35, 31, 32, 1);
            font-family: 'Open Sans', Arial;
            font-weight: 700;
            font-style: normal;
            font-size: 11px;
            text-align: left;
            padding-left: 11px;
            text-transform: uppercase;
          }
          .choose-col {
            .choose-select-div {
              margin: 15px 0px 5px 0px;
              .ant-select {
                width: 100%; // 250px
                height: 23px;
              }
            }
          }
          .add-field-btn {
            width: 100px;
            height: 25px;
            color: #e21c21;
            border: 1px solid #e21c21;
            margin: 10px 0px 30px 11px;
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            text-align: center;
            line-height: 20px;
            letter-spacing: 0px;
            text-transform: uppercase;
          }
          .border-less-input {
            color: rgb(56, 56, 56);
            font-family: 'Open Sans', Arial;
            font-weight: 400;
            font-size: 13px;
            text-align: left;
            box-shadow: none;
          }
          .field-row {
            margin: 10px 0px 10px 0px;
            .input-field {
              width: 78%;
              @media screen and (max-width: 1500px) {
                width: 70% !important;
              }
              @media screen and (max-width: 1300px) {
                width: 80% !important;
              }
            }
            .ant-select,
            .ant-input,
            .ant-picker {
              display: block;
              // width: 248px;
              height: 23px;
              font-size: 13px;
            }
            // .ant-input {
            //   width: 248px;
            //   height: 23px;
            // }
            .ant-picker:not(.ant-picker-range) .ant-picker-input input {
              padding-left: 0px;
            }
            .ant-space {
              svg {
                cursor: pointer;
                width: 18px;
                height: 18px;
                fill: #e21c21;
              }
            }
            .border-less-input {
              padding: 4px 10px;
              border: 1px solid #d9d9d9;
              height: 24px;
            }
          }
          .select-options-parent-row {
            .add-option-btn {
              width: 94px;
              color: rgba(226, 28, 33, 1);
              font-family: 'Open Sans', Arial;
              font-weight: 600;
              font-style: normal;
              font-size: 12px;
              height: 26px;
              margin-left: 40px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    .filter-div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      div {
        width: 80px;
        height: 52px;
        border: 1px solid rgba(226, 28, 33, 1);
        border-radius: 2px;
        line-height: 16px;
        letter-spacing: 0px;
        color: rgba(226, 28, 33, 1);
        font-family: 'Open Sans', Arial;
        font-weight: 600;
        font-style: normal;
        font-size: 13px;
        text-align: center;
        padding: 8px 0px;
        cursor: pointer;
        text-transform: uppercase;
      }
      .disabled-div {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    .batch-update-div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      div {
        width: 80px;
        height: 72px;
        border: 1px solid rgba(226, 28, 33, 1);
        border-radius: 2px;
        line-height: 16px;
        letter-spacing: 0px;
        color: rgba(226, 28, 33, 1);
        font-family: 'Open Sans', Arial;
        font-weight: 600;
        font-style: normal;
        font-size: 13px;
        text-align: center;
        padding: 8px 0px;
        cursor: pointer;
        text-transform: uppercase;
      }
    }

    .filter-header {
      border-bottom: 1px solid rgba(226, 28, 33, 1);
      padding: 10px 15px;
      .filter-col {
        display: flex;
        align-items: center;
        gap: 5px;
        svg {
          // font-size: 14px;
          height: 14px;
          width: 14px;
        }
        line-height: 17px;
        letter-spacing: 0px;
        color: rgba(226, 28, 33, 1);
        font-family: 'Open Sans', Arial;
        font-weight: 700;
        font-style: normal;
        font-size: 14px;
        text-align: left;
        .batch-icon-div {
          display: flex;
          align-items: center;
          gap: 6px;
          .patch-icon {
            width: 17px;
            height: 17px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              cursor: pointer;
            }
          }
        }
      }
      .cross-icon {
        height: 22px;
        width: 22px;
        margin: auto 0px;
        cursor: pointer;
        fill: #e21c21;
      }
    }
  }
}

.ims-doc-table {
  .expand-doc-parent {
    .less-padding-col {
      // padding-left: 4px !important;
      // padding-right: 4px !important;
      padding: 16px 4px 8px 0px !important;
    }
  }
  .disabled-row > td {
    cursor: not-allowed !important;
    div,
    span {
      cursor: not-allowed !important;
    }
  }
  .expand-doc-parent > td {
    border: none;
    // border-bottom: 1px solid #e2e2e2;
    .ant-picker,
    .ant-input,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 23px !important;
    }
    .ant-picker {
      width: 91px;
      padding: 1px 4px 0px 0px;
    }
    .ims-doc-first-level .ant-form-item-explain {
      display: none;
    }
    .ims-doc-first-level {
      .ant-select-arrow svg {
        fill: #171c26 !important;
      }
    }
  }
  .ant-table-expanded-row-level-1 > td {
    border: none;
    border-bottom: 1px solid #e2e2e2;
  }
  table > tbody > tr.expand-doc-parent td,
  table > tbody > tr.expand-doc-parent:hover,
  table > tbody > tr.expand-doc-parent:hover td {
    // background-color: #fff !important;
    // color: #231f20 !important;
    background-color: rgba(255, 186, 0, 0.8) !important;
    color: rgb(7, 7, 7) !important;
    input.ant-input {
      color: #171c26;
      text-overflow: ellipsis;
    }
    .attach-parent-div,
    .border-less-input,
    svg {
      // background-color: #e21c21 !important;
      color: #171c26 !important;
      fill: #e21c21 !important;
      cursor: pointer;
    }
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    // background: #fff;
    // background-color: #fff;
    // color: inherit;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td .rotate-icon,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover .rotate-icon {
    // fill: #231f20 !important;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td .attach-parent-div,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover .attach-parent-div {
    // color: #231f20;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td svg,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover svg {
    // fill: #f55056;
  }

  .ant-input:focus,
  .ant-input-focused {
    border-color: #f72f2f;
    box-shadow: 0 0 0 2px rgba(255, 164, 164, 0.834);
    border-right-width: 1px;
    outline: 0;
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #fff;
  }
  .ant-table-measure-row {
    display: none;
  }
  .ant-table-tbody > tr {
    .comment-td {
      word-break: break-word;
    }
  }

  .ant-table-tbody > .expand-doc-parent > td {
    padding: 16px 16px 8px 8px !important;
  }

  .ant-table-tbody > .expand-doc-parent > td.less-padding-col {
    padding: 16px 4px 8px 4px !important;
  }

  .ant-table-tbody > tr > td {
    padding: 16px 4px 8px 16px !important; //16px 16px 16px 16px !important;
    input.ant-input {
      height: inherit;
      text-overflow: ellipsis;
    }
    .ant-picker-large {
      padding: 6.5px 11px 6.5px 0px;
    }
    .ant-input-lg {
      padding: 4px 11px 4px 0px;
    }
    textarea.ant-input {
      max-height: 46px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 35px;
      padding: 0 7px !important;
    }
    // .ant-input {
    //   text-align: left;
    // }
    .ant-picker:not(.ant-picker-range) .ant-picker-input input,
    input.ant-input,
    .ant-picker-large,
    .ant-input-lg,
    .ant-select-selection-item,
    textarea.ant-input {
      font-size: 13px;
      font-family: 'Open Sans';
      color: rgb(35 31 32 / 1); // 50%
      padding-left: 4px !important;
      text-align: left;
    }

    .due-date {
      background-color: rgba(226, 28, 33, 1);
      border-width: 0px solid rgba(209, 209, 209, 1);
      border-radius: 2px;
      line-height: 22px; // 16px
      letter-spacing: 0px;
      color: rgba(255, 255, 255, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
      text-align: center;
      height: 22px;
    }

    .colors-div {
      display: flex;
      // gap: 10px;
      margin-top: 5px;
      .actual-div,
      .topic-div {
        display: flex;
        gap: 5px;
        align-items: center;
        color: #231f20;
        font-size: 8px;
        .good,
        .poor,
        .medium {
          height: 8px;
          width: 8px;
          border-radius: 50%;
          display: inline-block;
        }
        .good {
          background-color: rgba(67, 160, 71, 1);
        }
        .medium {
          background-color: rgba(251, 140, 0, 1);
        }
        .poor {
          background-color: rgba(249, 51, 36, 1);
        }
        .dot {
          height: 8px;
          width: 8px;
          border-radius: 50%;
          display: inline-block;
        }
        .filled {
          border: 1px solid rgba(84, 194, 66, 1);
          background-color: rgba(84, 194, 66, 1);
        }
        .unfilled {
          border: 1px solid rgba(84, 194, 66, 1);
        }
      }
    }
  }
  .doc-status-tag {
    color: #383838;
    margin-right: 0px;
    width: 85px;
    min-height: 20px;
    font-size: 11px;
    padding: 0px;
    font-weight: 500px;
    text-align: center;
    white-space: break-spaces;
    line-height: 16px;
  }
  .ims-doc-fields-form {
    .ant-form-item-label {
      padding: 0px;
      line-height: 1.2;
      label {
        line-height: 13px;
        letter-spacing: 0px;
        color: rgb(56, 56, 56);
        font-family: 'Open Sans', Arial;
        font-weight: 700;
        font-style: normal;
        font-size: 11px;
        height: 13px;
        text-transform: uppercase;
        padding-left: 10px;
      }
    }
    .ant-form-item-row {
      .ant-form-item-label
        > label.ant-form-item-required:not(
          .ant-form-item-required-mark-optional
        )::before {
        content: none;
      }
    }
    .ant-form-item-control-input {
      min-height: 24px;
      height: 24px;
      .ant-input {
        height: 24px;
        padding-left: 10px !important;
      }
      .ant-picker:not(.ant-picker-range) .ant-picker-input input {
        padding-left: 10px !important;
      }
      .ant-picker {
        padding: 0px 8px 0px 0px;
        height: 24px;
      }
      .ant-picker-input {
        padding-left: 0px !important;
        &::after {
          margin-right: 20px;
        }
      }
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 24px;
        padding: 0 5px !important;
      }
      .ant-select-single .ant-select-selector .ant-select-selection-item,
      .ant-select-single
        .ant-select-selector
        .ant-select-selection-placeholder {
        padding-left: 5px;
        line-height: 24px;
      }
      .ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector
        .ant-select-selection-search-input {
        height: 24px;
      }
    }
  }
  .ant-form-item-explain-error {
    padding-left: 10px;
  }
  .ant-form-item-has-error .ant-picker:not(.ant-picker-disabled) {
    box-shadow: none;
    border: 1px solid #ff4d4f;
    border-radius: 2px;
  }
}

.revisions-table {
  padding: 0px 0px 10px;
  .ant-table-thead > tr > th .ant-input {
    height: 25px !important;
  }
  .ant-table-tbody > tr.editable-row > td {
    .ant-select.ant-select-in-form-item,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 23px;
    }

    .ant-input-number {
      max-width: 60px;
    }
  }
  .ant-table-column-sorter {
    // padding-top: 25px !important;
  }
  .total {
    width: 50px;
    height: 25px;
    font-family: 'Open Sans', Arial;
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    text-align: center;
    line-height: 25px;
    letter-spacing: 0px;
    color: rgba(56, 56, 56, 1);
    background-color: rgba(245, 245, 245, 1);
    border: 1px solid rgba(141, 141, 141, 1);
    border-radius: 2px;
    margin: 0px auto;
  }
  .total-text {
    white-space: nowrap;
    line-height: 13px;
    letter-spacing: 0px;
    color: rgb(56, 56, 56);
    font-family: 'Open Sans', Arial;
    font-weight: 700;
    font-style: normal;
    font-size: 11px;
    text-align: center;
    opacity: 1;
    position: absolute;
  }
}

.revisions-parent-row {
  .revisions-heading-div {
    display: flex;
    width: 160px;
    .anticon {
      font-size: 20px;
    }
  }
  .heading-col {
    .heading-span {
      text-transform: uppercase;
      line-height: 16px;
      letter-spacing: 0px;
      color: #383838;
      font-family: 'Open Sans', Arial;
      font-weight: 700;
      font-style: normal;
      font-size: 13px;
      margin-left: 10px;
    }
  }
  .add-revisions-btn {
    width: 123px;
    border: 1px solid #e21c21;
    background-color: white;
    opacity: 1;
    filter: none;
    letter-spacing: 0px;
    color: rgba(226, 28, 33, 1);
    font-family: 'Open Sans', Arial;
    height: 23px;
    font-style: normal;
    text-align: center;
    border: 1px solid rgba(226, 28, 33, 1);
    border-radius: 2px;
    line-height: 14px;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    margin-left: 10px;
    svg {
      fill: #e21c21;
    }
  }
}

.ims-doc-readonly-input {
  cursor: text;
  .ant-input {
    border: none;
  }
  textarea {
    overflow: hidden;
    resize: none;
    cursor: text;
  }
  .ant-form-item-label {
    cursor: text;
  }
  .ant-input-disabled {
    cursor: text;
    background-color: white;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-picker.ant-picker-disabled {
    color: #231f20;
    background: #fff;
    cursor: text;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker.ant-picker-disabled {
    border: none;
  }
  .ant-picker-input::after {
    content: none;
    width: 0px;
  }
  .ant-picker-input > input[disabled] {
    cursor: text;
    border: none;
  }
  .ant-select-arrow {
    display: none;
  }
  .ant-input:focus,
  .ims-doc-table .ant-input-focused {
    border-color: transparent;
    box-shadow: none;
    border-right-width: 0px;
    outline: 0;
  }
}

.doc-table-is-exapnded {
  .ant-table-tbody {
    // cursor: not-allowed;
    .expand-doc-parent,
    .ant-table-expanded-row,
    .revisions-parent-row,
    .revisions-table .ant-table-tbody {
      cursor: pointer;
    }
  }
}

.left-axis-legend-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 60px;
  div {
    font-weight: 600;
    color: rgb(56, 56, 56);
    fill: rgb(56, 56, 56);
    font-size: 13px;
    font-family: 'Open Sans';
    line-height: 16px;
    transform: rotate(270deg);
    transform-origin: left center;
    white-space: nowrap;
  }
}

.left-axis-legend-col-no-padding {
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-bottom: 48px;

  div {
    font-weight: 600;
    color: rgb(56, 56, 56);
    fill: rgb(56, 56, 56);
    font-size: 13px;
    font-family: 'Open Sans';
    line-height: 16px;
    transform: rotate(270deg);
    transform-origin: left center;
    white-space: nowrap;
  }
}

.page-top-div {
  .add-region-btn,
  .add-region-btn[disabled],
  .add-region-btn[disabled]:hover,
  .add-region-btn[disabled]:focus,
  .add-region-btn[disabled]:active {
    width: 107px !important;
  }
  .ant-btn[disabled] {
    cursor: not-allowed !important;
  }

  .add-site-type-btn,
  .add-site-type-btn[disabled],
  .add-site-type-btn[disabled]:hover,
  .add-site-type-btn[disabled]:focus,
  .add-site-type-btn[disabled]:active {
    width: 114px !important;
  }

  .add-couch-type-btn,
  .add-couch-type-btn[disabled],
  .add-couch-type-btn[disabled]:hover,
  .add-couch-type-btn[disabled]:focus,
  .add-couch-type-btn[disabled]:active {
    width: 133px !important;
  }

  .add-learner-type-btn,
  .add-learner-type-btn[disabled],
  .add-learner-type-btn[disabled]:hover,
  .add-learner-type-btn[disabled]:focus,
  .add-learner-type-btn[disabled]:active {
    width: 149px !important;
  }

  .add-programme-type-btn,
  .add-programme-type-btn[disabled],
  .add-programme-type-btn[disabled]:hover,
  .add-programme-type-btn[disabled]:focus,
  .add-programme-type-btn[disabled]:active {
    width: 175px !important;
  }

  .add-status-btn,
  .add-status-btn[disabled],
  .add-status-btn[disabled]:hover,
  .add-status-btn[disabled]:focus,
  .add-status-btn[disabled]:active {
    width: 103px;
    border: 1px solid #e21c21;
    background-color: white;
    font-family: Arial, sans-serif, Arial;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    text-align: center;
    line-height: 20px;
    letter-spacing: 0px;
    height: 30px;
    color: #e21c21;
    svg {
      fill: #e21c21;
    }
  }
}
.ant-input:hover {
  border-color: #f72f2f !important;
  border-right-width: 1px;
  box-shadow: 0 0 0 2px rgbi(235 7 7 / 20%) !important;
}
.production-manager-parent-row .heading-col .heading-span {
  line-height: 17px;
  letter-spacing: 0px;
  color: rgba(226, 28, 33, 1);
  font-family: 'Open Sans', Arial;
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .table {
    padding-top: 20px;
  }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.row-dragging .drag-visible {
  visibility: visible;
}

.project-statuses-table {
  margin-top: 40px;
  .ant-table-thead > tr th {
    padding: 12px 12px !important;
    color: rgba(35, 31, 32, 1);
    font-family: 'Open Sans', Arial;
    font-weight: 700;
    font-size: 11px;
    text-transform: uppercase;
    line-height: 13px;
  }
}
.table {
  padding-bottom: 40px;
}
@media (max-width: 1202px) {
  .project-statuses-table {
    margin-top: 24px;
    padding: 0px;
  }
}

.ant-table-summary > tr > th,
.ant-table-summary > tr > td {
  border-bottom: none !important;
}

.classes-learners-table {
  .ant-table-tbody > tr > td {
    padding: 8px 0px 8px 8px !important;
  }
}

.export-popover {
  .ant-popover-content {
    border: 1px solid rgba(226, 28, 33, 1);
    border-radius: 2px;
    .ant-popover-arrow-content {
      box-shadow: 1px 1px 1px rgba(226, 28, 33, 1);
    }
    .ant-popover-inner {
      border-radius: 2px;
      height: 185px; // 228px
      width: 304px;
      .title {
        line-height: 16px;
        letter-spacing: 0px;
        color: rgb(226, 28, 33);
        font-family: 'Open Sans', Arial;
        font-weight: 600;
        font-style: normal;
        font-size: 13px;
        text-align: left;
      }
      .ant-popover-inner-content {
        .invalid-text {
          background-color: rgb(247, 247, 247);
        }
        .valid-text {
          background-color: #fff;
        }
        .valid-text-btn {
          background-color: rgba(226, 28, 33, 1);
          color: white;
        }
        .invalid-text-btn {
          background-color: #fff;
          color: rgba(226, 28, 33, 1);
        }
        textarea[readonly] {
          border-color: transparent;
        }
      }
    }
  }
  .ant-popover-title {
    padding: 12px 0px 8px 0px;
    margin: 0px 12px 0px 12px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    border-bottom: 1px solid rgba(226, 28, 33, 1);
    svg {
      font-size: 14px;
      fill: rgba(226, 28, 33, 1);
    }
  }
  .ant-popover-inner-content {
    .parent-div {
      margin-bottom: 0px;
      .choose-col {
        .choose-select-div {
          label {
            line-height: 13px;
            letter-spacing: 0px;
            color: rgb(56, 56, 56);
            font-family: 'Open Sans', Arial;
            font-weight: 700;
            font-style: normal;
            font-size: 11px;
            padding-left: 7px;
          }
        }
        .ant-select {
          height: 23px;
          width: 248px;
          line-height: 16px;
          letter-spacing: 0px;
          color: rgba(56, 56, 56, 1);
          font-family: 'Open Sans', Arial;
          font-weight: 400;
          font-style: normal;
          font-size: 13px;
        }

        .readonly-select-row {
          .ant-select-disabled.ant-select:not(.ant-select-customize-input)
            .ant-select-selector {
            color: rgba(56, 56, 56, 1);
            background: #fff;
            cursor: text;
          }

          .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border: none;
          }

          cursor: text;
          .ant-input {
            border: none;
          }
          .ant-select-disabled.ant-select:not(.ant-select-customize-input)
            .ant-select-selector,
          .ant-picker.ant-picker-disabled {
            color: #231f20;
            background: #fff;
            cursor: text;
          }
          .ant-select:not(.ant-select-customize-input) .ant-select-selector,
          .ant-picker.ant-picker-disabled {
            border: 1px solid transparent;
          }
          .ant-select-arrow {
            display: none;
          }
        }
      }
      .success-row {
        .success-text {
          line-height: 16px;
          letter-spacing: 0px;
          color: rgb(226, 28, 33);
          font-family: 'Open Sans', Arial;
          font-weight: 600;
          font-style: italic;
          font-size: 13px;
          text-align: left;
        }
        .close-btn {
          width: 80px;
          height: 24px;
          color: rgb(226, 28, 33);
          border: 1px solid rgb(226, 28, 33);
          margin: 0px 35%;
          font-size: 13px;
        }
      }
    }
    .export-btn {
      line-height: 1;
      color: #fff;
      background: rgba(226, 28, 33, 1);
      border-color: rgba(226, 28, 33, 1);
      min-width: 135px;
      height: 32px;
      margin-top: 20px;
      svg {
        height: 14px;
        width: 14px;
        path {
          fill: #fff;
        }
      }
      span {
        padding-left: 5px;
      }
    }
  }
}

.import-popover {
  .ant-popover-content {
    border: 1px solid rgba(226, 28, 33, 1);
    border-radius: 2px;
    .ant-popover-arrow-content {
      box-shadow: 1px 1px 1px rgba(226, 28, 33, 1);
    }
    .ant-popover-inner {
      border-radius: 2px;
      min-height: 280px;
      height: 100%;
      // max-height: 340px;
      width: 304px;
      .title {
        line-height: 16px;
        letter-spacing: 0px;
        color: rgb(226, 28, 33);
        font-family: 'Open Sans', Arial;
        font-weight: 600;
        font-style: normal;
        font-size: 13px;
        text-align: left;
      }
      .ant-popover-inner-content {
        .invalid-text {
          background-color: rgb(247, 247, 247);
        }
        .valid-text {
          background-color: #fff;
        }
        .valid-text-btn {
          background-color: rgba(226, 28, 33, 1);
          color: white;
        }
        .invalid-text-btn {
          background-color: #fff;
          color: rgba(226, 28, 33, 1);
        }
        textarea[readonly] {
          border-color: transparent;
        }
      }
    }
  }
  .ant-popover-title {
    padding: 12px 0px 8px 0px;
    margin: 0px 12px 0px 12px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    border-bottom: 1px solid rgba(226, 28, 33, 1);
    svg {
      font-size: 14px;
      fill: rgba(226, 28, 33, 1);
    }
  }
  .ant-popover-inner-content {
    .parent-div {
      margin-bottom: 0px;
      .choose-col {
        .choose-select-div {
          label {
            line-height: 13px;
            letter-spacing: 0px;
            color: rgb(56, 56, 56);
            font-family: 'Open Sans', Arial;
            font-weight: 700;
            font-style: normal;
            font-size: 11px;
            padding-left: 7px;
          }
        }
        .ant-select {
          height: 23px;
          width: 248px;
          line-height: 16px;
          letter-spacing: 0px;
          color: rgba(56, 56, 56, 1);
          font-family: 'Open Sans', Arial;
          font-weight: 400;
          font-style: normal;
          font-size: 13px;
        }

        .readonly-select-row {
          .ant-select-disabled.ant-select:not(.ant-select-customize-input)
            .ant-select-selector {
            color: rgba(56, 56, 56, 1);
            background: #fff;
            cursor: text;
          }

          .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border: none;
          }

          cursor: text;
          .ant-input {
            border: none;
          }
          .ant-select-disabled.ant-select:not(.ant-select-customize-input)
            .ant-select-selector,
          .ant-picker.ant-picker-disabled {
            color: #231f20;
            background: #fff;
            cursor: text;
          }
          .ant-select:not(.ant-select-customize-input) .ant-select-selector,
          .ant-picker.ant-picker-disabled {
            border: 1px solid transparent;
          }
          .ant-select-arrow {
            display: none;
          }
        }
      }
      .success-row {
        .ant-col {
          width: 100%;
        }
        .file-text {
          line-height: 16px;
          letter-spacing: 0px;
          font-family: 'Open Sans', Arial;
          font-weight: 600;
          font-size: 13px;
          text-align: left;
          color: rgb(56, 56, 56);
          font-style: normal;
          text-decoration: underline;
          cursor: pointer;
        }
        .upload-btn {
          width: 120px;
          height: 24px;
          color: rgb(226, 28, 33);
          border: 1px solid rgb(226, 28, 33);
          // margin: 0px 35%;
          font-size: 13px;
          display: flex;
          align-items: center;
          svg {
            height: 14px;
            width: 14px;
            path {
              // fill: #fff;
            }
          }
          span {
            padding-left: 5px;
          }
        }
      }
      .successfully-uploaded {
        .disabled-text {
          line-height: 16px;
          letter-spacing: 0px;
          color: rgb(56, 56, 56);
          font-family: 'Open Sans', Arial;
          font-weight: 600;
          font-style: normal;
          font-size: 13px;
          text-align: left;
          opacity: 0.48;
          text-decoration: underline;
          padding-left: 6px;
        }
        .file-row {
          .del-icon {
            height: 14px;
            width: 14px;
            cursor: pointer;
            path {
              fill: rgba(226, 28, 33, 1);
            }
          }
          .file-text {
            line-height: 16px;
            letter-spacing: 0px;
            color: rgb(0, 80, 239);
            font-family: 'Open Sans', Arial;
            font-weight: 400;
            font-style: normal;
            font-size: 13px;
            text-decoration: underline;
            padding-left: 6px;
          }
          .span-div {
            span {
              line-height: 16px;
              letter-spacing: 0px;
              color: rgb(56, 56, 56);
              font-family: 'Open Sans', Arial;
              font-weight: 500;
              font-style: italic;
              font-size: 13px;
            }
          }
        }
        .import-file-btn {
          margin: 0px 25%;
          line-height: 1;
          color: #fff;
          background: rgba(226, 28, 33, 1);
          border-color: rgba(226, 28, 33, 1);
          width: 120px;
          height: 24px;
          margin-bottom: 30px;
          svg {
            height: 14px;
            width: 14px;
            path {
              fill: #fff;
            }
          }
          span {
            padding-left: 5px;
          }
        }
        .confirm-row {
          .text-col {
            line-height: 16px;
            letter-spacing: 0px;
            color: rgb(226, 28, 33);
            font-family: 'Open Sans', Arial;
            font-weight: 600;
            font-style: normal;
            font-size: 13px;
            text-align: left;
          }
          .btns-col {
            display: flex;
            justify-content: center;
            gap: 20px;
            .confirm-btn {
              height: 25px;
              width: 84px;
              border: 1px solid rgba(226, 28, 33, 1);
              background-color: rgba(226, 28, 33, 1);
              border-radius: 2px;
              line-height: 14px;
              letter-spacing: 0px;
              color: rgba(255, 255, 255, 1);
              font-family: 'Open Sans', Arial;
              font-weight: 600;
              font-style: normal;
              font-size: 12px;
              text-align: center;
            }
            .cancel-btn {
              height: 25px;
              width: 84px;
              border: 1px solid rgba(85, 85, 85, 1);
              background-color: rgba(255, 255, 255, 1);
              border-radius: 2px;

              line-height: 14px;
              letter-spacing: 0px;
              color: rgba(85, 85, 85, 1);
              font-family: 'Open Sans', Arial;
              font-weight: 600;
              font-style: normal;
              font-size: 12px;
              text-align: center;
            }
          }
        }
        .close-btn-row {
          .success-text {
            line-height: 16px;
            letter-spacing: 0px;
            color: rgb(226, 28, 33);
            font-family: 'Open Sans', Arial;
            font-weight: 600;
            font-style: italic;
            font-size: 13px;
            text-align: left;

            text-align: left;
            height: 16px;
          }
          .close-btn {
            width: 80px;
            height: 24px;
            color: rgb(226, 28, 33);
            border: 1px solid rgb(226, 28, 33);
            margin: 0px 35%;
            font-size: 13px;
          }
        }
      }
    }
    .export-btn {
      line-height: 1;
      color: #fff;
      background: rgba(226, 28, 33, 1);
      border-color: rgba(226, 28, 33, 1);
      width: 135px;
      height: 32px;
      padding: 4px 0px !important;
      margin-top: 20px;
      svg {
        height: 14px;
        width: 14px;
        path {
          fill: #fff;
        }
      }
      span {
        padding-left: 5px;
      }
    }
  }
}

textarea::-webkit-scrollbar {
  width: 0.3em;
}

textarea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
}

textarea::-webkit-scrollbar-thumb {
  background-color: #3838387c;
  // outline: 1px solid slategrey;
}

// .ant-input[disabled] {
//   cursor: not-allowed !important;
// }

.ant-input[readonly]:focus {
  box-shadow: none;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: #383838;
}

.graph-parent-div {
  svg ~ div {
    div > span {
      text-transform: capitalize;
    }
  }
}

.graphs-parent-div::-webkit-scrollbar {
  height: 6px;
}

.graphs-parent-div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
}

.graphs-parent-div::-webkit-scrollbar-thumb {
  background-color: #3838387c;
  // outline: 1px solid slategrey;
}

@primary-color: #e21c21;