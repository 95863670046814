@sidebar-background-color: #070707;
@sidebar-color-hover: #ffba00;
.layout-content {
  @media screen and (max-width: 991px) {
    padding: 0px 15px !important;
  }
}

.ant-layout-sider {
  background: @sidebar-background-color;
  .ant-menu.ant-menu-inline-collapsed
    > .ant-menu-item
    .ant-menu-item-icon
    + span {
    display: none;
  }
}
.most-parent-layout {
  height: 100vh;
  .site-layout {
    background-color: rgba(240, 240, 240, 1);
    display: flex;
  }
}

.layout-content {
  padding: 0px 25px;
  background-color: rgba(240, 240, 240, 1);
  min-height: 100vh;
  overflow-y: auto;
}

.main-sidebar-collapsed {
  min-height: 100vh;
  background-color: @sidebar-background-color;
  width: 70px;
  min-width: 70px;
  max-width: 70px;
  text-align: center;
  color: #fff;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: sticky;
  .ant-menu-inline-collapsed {
    .ant-menu-submenu {
      .ant-menu-submenu-title {
        padding: 0px 20px !important;
        display: block;
        svg {
          margin: 17px 0px !important;
        }
        span > span {
          color: #fff !important;
        }
      }
    }
  }
}
.ant-menu-submenu-popup {
  .ant-menu {
    background: #070707;
  }
  .select-project {
    .ant-menu-title-content {
      span {
        padding-left: 24px !important;
      }
    }
  }
  .projects-submenu {
    .ant-menu-submenu-title {
      color: #fff;
      span {
        padding-left: 8px;
        height: 60px !important;
        line-height: 12px !important;
        .project-name,
        .client-name {
          line-height: 30px !important;
          height: 30px !important;
          span {
            line-height: 17px !important;
            // height: auto !important;
          }
        }
      }
    }
  }
  .project-doc-selected-parent {
    .ant-menu-submenu-title {
      color: @sidebar-color-hover;
      svg {
        fill: @sidebar-color-hover;
      }
    }
  }
}

.main-sidebar {
  min-height: 100vh;
  background-color: @sidebar-background-color;
  width: 260px;
  min-width: 260px;
  max-width: 260px;
  color: #fff;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: sticky;
}

.sidebar-pin-icon {
  position: absolute;
  top: 5px;
  right: 6px;
  display: flex;
  z-index: 10;
  cursor: pointer;
  svg {
    height: 12px;
    width: 12px;
    fill: #e2e2e2;
  }
}

.sidebar-child-div {
  display: flex;
  // padding:  "10px 30px",
  //   borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
  cursor: pointer;
  // margin: `5px 0px`,
  height: 90px;
  padding: 15px 0px;
  justify-content: center;
}

.nav-collapsed-img-div {
  // display: "table",
  // verticalAlign: "-0.5em",
  height: 30px;
  width: 68px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.nav-uncollapsed-img-div {
  width: 196px;
  height: 29px;
  img {
    width: 100%;
    height: 200%;
    object-fit: contain;
    cursor: pointer;
  }
}

.menu-item-for-top-border {
  position: relative;
  width: 100%;
}

.menu-item-for-top-border::before {
  content: '';
  position: absolute;
  top: 0;
  left: 5px;
  border-top: 1px solid rgba(226, 226, 226, 11%);
  width: 250px;
}

.menu-item-for-top-border::after {
  content: '';
  position: absolute;
  top: 0;
  left: 5px;
  border-bottom: 1px solid rgba(226, 226, 226, 11%);
  width: 250px;
}

.menu-item-for-top-border-collapsed {
  position: relative;
  width: 100%;
}

.menu-item-for-top-border-collapsed::before {
  content: '';
  position: absolute;
  top: 0;
  left: 5px;
  border-top: 1px solid rgba(226, 226, 226, 11%);
  width: 70px;
}

.menu-item-for-top-border-collapsed::after {
  content: '';
  position: absolute;
  top: 0;
  left: 5px;
  border-bottom: 1px solid rgba(226, 226, 226, 11%);
  width: 70px;
}

.sidebar-notification-menu-item {
  background-color: @sidebar-background-color;
  font-size: 15px;
  padding-left: 20px;
  height: 60px;
  display: flex;
  justify-content: start;
  align-items: center;
  svg {
    font-size: 27;
    color: white;
    fill: #e2e2e2;
  }
}

.notification-icon {
  font-size: 19px;
  color: white;
  fill: #e2e2e2;
}

.sidebar-custom > li:nth-last-child(3) {
  border-bottom: 1px solid hsla(0, 0%, 88.6%, 0.11);
}

.ant-menu-submenu-popup .subitems-custom,
.ant-menu-item-selected {
  display: flex;
  align-items: center;
}

.ant-menu-vertical.ant-menu-sub > .ant-menu-item {
  display: flex;
  align-items: center;
}

.sidebar-custom {
  max-height: 85vh;
  overflow-y: scroll;
  .ant-menu-submenu-title:hover {
    color: @sidebar-color-hover;
  }
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  .sidebar-settings-divider {
    margin: 1px auto;
    border-top: 1px solid #e2e2e21c;
    width: 217px;
    min-width: 217px;
  }
}

.sidebar-custom::-webkit-scrollbar {
  display: none;
}

.sidebar-custom,
.ant-menu-submenu-popup {
  background-color: @sidebar-background-color;
  font-size: 16px;
  max-width: 260px;
  min-width: 70px;
  padding-left: 0;
  .ant-menu-submenu-arrow {
    display: none;
  }
  .sidebar-profile-icon {
    .ant-avatar {
      font-size: 27px;
      display: flex;
      align-items: center;
      height: 30px;
      width: 30px;
    }
  }
  .ant-menu-item-selected {
    .ant-menu-item-icon,
    .anticon {
      color: @sidebar-color-hover;
      fill: @sidebar-color-hover;
      svg path {
        color: @sidebar-color-hover !important;
        fill: @sidebar-color-hover !important;
      }
    }
    .ant-menu-item-icon svg {
      fill: @sidebar-color-hover !important;
    }
  }

  .select-project {
    .dropdown-icon {
      svg {
        fill: #fff !important;
        path {
          fill: #fff !important;
        }
      }
    }
    // &:hover {
    //   .dropdown-icon {
    //     svg {
    //       fill: @sidebar-color-hover !important;
    //       path {
    //         fill: @sidebar-color-hover !important;
    //       }
    //     }
    //   }
    // }
  }
  .sidebar-settings-divider {
    margin: 1px auto;
    border-top: 1px solid #e2e2e21c;
    width: 217px;
    min-width: 217px;
  }
  .settings-title-span {
    margin-left: 10px;
    color: #ffffff;
    font-family: 'Open Sans';
  }

  .ant-menu-submenu {
    .ant-menu-sub svg {
      margin-left: 15px !important;
    }
  }

  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    .project-icon,
    span {
      color: @sidebar-color-hover;
      fill: @sidebar-color-hover;
      path {
        color: @sidebar-color-hover !important;
        fill: @sidebar-color-hover !important;
      }
    }
  }

  .ant-menu-sub.ant-menu-inline > .ant-menu-item,
  .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 50px;
    line-height: 50px;
  }

  .ant-menu-submenu-title > span {
    width: 90%;
    span {
      width: 100%;
    }
  }

  .ant-menu-item:hover,
  // .ant-menu-submenu:hover,
  .projects-submenu:hover,
  .project-doc-selected-parent {
    background-color: @sidebar-background-color;
    color: @sidebar-color-hover !important;

    .ant-menu-submenu-title {
      .project-icon,
      svg {
        fill: @sidebar-color-hover !important;
        color: @sidebar-color-hover !important;
        path {
          fill: @sidebar-color-hover !important;
          color: @sidebar-color-hover !important;
        }
      }
    }

    div,
    .ant-menu-submenu-title,
    span {
      .settings-title-span,
      .dot-icon,
      .project-icon {
        fill: @sidebar-color-hover;
        color: @sidebar-color-hover;
        path {
          fill: @sidebar-color-hover;
          color: @sidebar-color-hover;
        }
      }
    }

    .ant-menu-sub.ant-menu-inline
      > .ant-menu-submenu
      > .ant-menu-submenu-title {
      height: 50px;
      line-height: 50px;
    }

    .projects-submenu {
      .ant-menu-submenu-title {
        span,
        span span {
          width: 100%;
          font-size: 13px;
        }
      }
    }
    .profile-username-div {
      line-height: 24px;
      p {
        margin-bottom: 0px;
      }
      p,
      span {
        background-color: @sidebar-background-color !important;
        color: @sidebar-color-hover;
      }
    }
  }

  .projects-submenu {
    &:hover {
      .projects-subitems-custom {
        &:hover {
          color: @sidebar-color-hover;
          svg {
            fill: @sidebar-color-hover;
            color: @sidebar-color-hover;
          }
        }
        // color: @sidebar-color-hover !important;
        // svg {
        //   fill: @sidebar-color-hover;
        //   color: @sidebar-color-hover;
        // }
      }
    }
    .projects-subitems-custom {
      padding: 0px 5px 0px 45px !important;
    }
  }

  .projects-subitems-custom {
    height: 40px !important;
    line-height: 40px !important;
    padding: 0px 5px 0px 45px !important;
    .ant-menu-title-content {
      height: 40px;
      .project-menu-div {
        line-height: 40px;
        height: 40px;
        .project-name-div {
          line-height: 24px;
          p {
            margin-bottom: 0px;
            line-height: 1;
          }
          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .projects-subitems-custom:hover {
        p,
        span {
          color: @sidebar-color-hover !important;
        }
      }
    }
  }

  .subitems-custom:hover {
    svg {
      fill: @sidebar-color-hover;
      color: @sidebar-color-hover;
    }
  }

  .ant-menu-item {
    background-color: @sidebar-background-color;
    font-size: 15px;
    padding-left: 20px !important;
    height: 60px;
    font-family: 'Open Sans';
    display: flex;
    justify-content: start;
    align-items: center;
    color: #ffffff;
    svg {
      fill: #ffffff;
    }

    .ant-menu-submenu {
      background-color: @sidebar-background-color;
      font-size: 15px;
      height: 60px;
      font-family: 'Open Sans';
      display: flex;
      justify-content: start;
      align-items: center;
      color: #ffffff;
      svg {
        fill: #ffffff;
        width: 27px;
        height: 27px;
        font-size: 27px;
      }
    }
  }

  .ant-menu-submenu > .ant-menu-submenu-title {
    font-size: 15px;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 20px !important;
    // color: #ffffff;
    svg {
      path {
        fill: #ffffff;
      }
      fill: #ffffff;
      width: 27px;
      height: 27px;
      font-size: 27px;
    }
  }
  .subitems-custom {
    // border-top: 1px solid rgba(226, 226, 226, 11%);
    background-color: @sidebar-background-color;
    font-size: 14px;
    height: 60px;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 0px;
    // color: #e2e2e2;
  }

  .ant-menu-submenu {
    border-bottom: 1px solid rgba(226, 226, 226, 11%);

    .ant-menu-submenu-title {
      font-size: 15px;
      // svg,
      // svg path {
      //   fill: #ffffff;
      // }
    }
  }
  .ant-menu-item:not(:last-child) {
    margin-bottom: 0px;
    margin-top: 0px;

    .ant-menu-submenu {
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
  .ant-menu-submenu .ant-menu-submenu-title {
    margin-bottom: 0px;
    margin-top: 0px;
    // padding-left: 0px !important;
    background-color: @sidebar-background-color;
  }
  .profile-avatar {
    width: 27px;
    height: 27px;
    font-size: 27px;
    display: flex;
    align-items: center;
  }
  .profile-svg-icon {
    width: 27px;
    height: 27px;
    font-size: 27px;
    fill: #e2e2e2;
  }
  .profile-menu-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .profile-username-div {
      line-height: 24px;
      span {
        line-height: 16px;
        color: rgba(255, 255, 255, 1);
        font-family: 'Open Sans', Arial;
        font-weight: 300;
        font-size: 11px;
        text-align: left;
      }
      p {
        line-height: 16px;
        color: rgba(255, 255, 255, 1);
        font-family: 'Open Sans', Arial;
        font-weight: 500;
        font-size: 15px;
        text-align: left;
        margin-bottom: 0px;
      }
    }
  }
  .ant-badge-count {
    min-width: 18px;
    height: 17px;
    padding: 0 5px;
    font-size: 12px;
    line-height: normal;
    margin-right: 5px;
    margin-top: 2px;
  }
  .notification-icon {
    font-size: 19px;
    svg {
      fill: #ffffff !important;
    }
  }
  .notification-icon:hover {
    font-size: 19px;
    svg {
      fill: @sidebar-color-hover !important;
    }
  }
  .dot-icon {
    font-size: 27px;
    display: flex;
    align-items: center;
    width: 10px;
    height: 10px;
  }
  .ant-menu-vertical .ant-menu-submenu-title {
    padding-right: 20px !important;
  }
  .subitems-custom {
    background-color: @sidebar-background-color;
    display: flex;
    align-items: flex-start;
  }

  .ant-menu-item-selected {
    background-color: @sidebar-background-color !important;
    .ant-menu-item-icon + span,
    .anticon + span {
      background-color: @sidebar-background-color !important;
      color: @sidebar-color-hover;
    }
    .profile-username-div {
      line-height: 24px;
      p {
        margin-bottom: 0px;
      }
      p,
      span {
        background-color: @sidebar-background-color !important;
        color: @sidebar-color-hover;
      }
    }
  }
  .logout-menu-item {
    position: absolute;
    bottom: 0;
    z-index: 1;
    transition: all 0.2s;
    // background-color: transparent;
    line-height: 21;
    svg {
      font-size: 21px;
      width: 21px;
      height: 21px;
    }
    .ant-spin {
      margin-left: 20px;
    }
  }

  .project-doc-selected {
    .ant-menu-item-icon + span,
    .anticon + span,
    svg {
      color: @sidebar-color-hover;
      fill: @sidebar-color-hover;
    }
    .profile-username-div {
      p {
        margin-bottom: 0px;
      }
      p,
      span {
        background-color: @sidebar-background-color !important;
        color: @sidebar-color-hover;
      }
    }
  }
}

.ant-menu-inline .ant-menu-item {
  margin-top: 0px;
  margin-bottom: 0px;
}

.select-projects-modal {
  height: 520px;
  background-color: transparent !important;
  box-shadow: none;
  margin: 0;
  top: 250px;
  left: 5px;
  .ant-checkbox {
    font-size: 16px;
    &::after {
      border: 1px solid #383838;
    }
  }
  .ant-checkbox-inner {
    height: 16px;
    width: 16px;
    &::after {
      width: 6px;
      height: 12px;
    }
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-inner {
    border-color: #383838;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #fff;
      border-color: #383838;
      &::after {
        position: absolute;
        display: table;
        border: 2px solid #383838;
        border-top: 0;
        border-left: 0;
        transform: rotate(45deg) scale(1) translate(-50%, -50%);
        opacity: 1;
        transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
        content: ' ';
      }
    }
  }
  .ant-modal-header {
    padding: 16px 16px 16px 10px;

    background-color: #f8f8f8;
    .ant-modal-title {
      text-transform: uppercase;
      color: rgb(56, 56, 56);
      font-family: 'Open Sans', Arial;
      font-weight: 700;
      font-size: 12px;
    }
    .header-row {
      .checkbox-div {
        display: flex;
        align-items: center;
        gap: 5px;
        line-height: 16px;
        letter-spacing: 0px;
        color: rgba(35, 31, 32, 1);
        font-family: 'Open Sans', Arial;
        font-weight: 400;
        font-style: normal;
        font-size: 13px;
        text-align: left;
      }
    }
  }
  .ant-modal-body {
    padding: 0px 10px;
    max-height: 430px;
    min-height: 200px;
    border-radius: 2px;
    overflow-y: overlay;
    &::before {
      content: '';
      display: block;
      width: 100%;
      margin: 0em auto 0;
      border-bottom: 2px solid rgba(226, 226, 226, 1);
    }

    .checkbox-parent-div {
      margin: 10px 0px;
      display: flex;
      flex-direction: column;
      .checkbox-col {
        display: flex;
        align-items: center;
        gap: 5px;
        line-height: 16px;
        letter-spacing: 0px;
        color: rgba(35, 31, 32, 1);
        font-family: 'Open Sans', Arial;
        font-weight: 400;
        font-style: normal;
        font-size: 13px;
        text-align: left;
      }
    }
  }
  .ant-modal-body::-webkit-scrollbar {
    display: none;
  }

  .ant-modal-footer {
    padding: 16px 16px;
    .apply-btn {
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid rgba(226, 28, 33, 1);
      border-radius: 2px;
      width: 80px;
      height: 22px;
      line-height: 14px;
      letter-spacing: 0px;
      color: rgba(226, 28, 33, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 400;
      font-style: normal;
      font-size: 12px;
      text-align: center;
    }
    .cancel-btn {
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid grey;
      border-radius: 2px;
      width: 80px;
      height: 22px;
      line-height: 14px;
      letter-spacing: 0px;
      color: grey;
      font-family: 'Open Sans', Arial;
      font-weight: 400;
      font-style: normal;
      font-size: 12px;
      text-align: center;
    }
  }

  .ant-modal-content,
  .ant-modal-header {
    border-radius: 2px !important;
    // border-bottom: 1px solid #eb078e;
    border-bottom: none;
  }

  .ant-modal-content {
    border: 1px solid rgba(209, 209, 209, 1);
    box-shadow: 1px 1px 3px rgb(209, 209, 209 / 50%);
    margin-right: 2px;
    margin-left: 2px;
    background-color: #f8f8f8;
  }

  .ant-modal-close-x {
    font-size: 14px;
  }
  .ant-modal-close {
    display: none;
    right: -5px;
    top: -10px;
  }
  .anticon-close {
    color: grey !important;
  }

  input[type='checkbox'] {
    color: @sidebar-color-hover;
    background-color: yellow;
  }
}

// @media screen and (max-width: 575px) {
//   .select-projects-modal {
//     right: 0;
//     margin-left: auto;
//   }
// }

@primary-color: #e21c21;