.users-manager-parent-row {
  .heading-col {
    padding: 2px 25px 8px 12px;
    color: #e21c21;
  }
  .add-user-btn {
    border: 1px solid #e21c21;
    width: fit-content !important;
    // height: 40px;
    color: #e21c21;
    text-transform: uppercase;
    svg {
      fill: #e21c21;
    }
  }
}
.texts {
  line-height: 12px;
  letter-spacing: 0px;
  color: rgba(113, 113, 113, 1);
  font-family: 'Open Sans', Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  text-align: center;
}
.text {
  font-weight: 400 !important;
  font-style: normal !important;
}

.users-manager-table {
  .ant-table-column-title {
    .ant-input,
    .ant-select-selector {
      border-color: #c6c6c6;
    }
    .ant-select-selector,
    .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      height: 25px;
    }
  }
}

@primary-color: #e21c21;